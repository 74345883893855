import { Injectable } from '@angular/core';
import { StringUtils } from 'app/shared/helpers/stringUtils';
import Swal from 'sweetalert2';
import { DashboardComponent } from 'app/modules/admin/dashboard/dashboard.component';

// declare var showNotification: any;

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    constructor() {}
    notifications = [];
    bottomBarControls = {
        isShowing: false,
        timer: 10000,
    };

    /**
     * Creates an alert with default configurations
     * @param notification - The Message in the dialog
     * @param type
     * @param title - The title to the dialog
     * @Deprecated - will be made private in subsequent releases - use success, error, warning
     */
    add(notification: string, type: string, title?: string): void {
        this.notifications.push(notification);
        this.showNotification(
            'center',
            title ? title : type,
            notification,
            type
        );
    }

    displayMessage(notification: string, type: string, title?: string): void {
        this.notifications.push(notification);
        this.showMessage('center', title ? title : type, notification);
    }

    success(message: string): void {
        this.add(message, 'success');
    }

    error(message: string): void {
        this.add(message, 'error');
    }

    warning(message: string, title?: string): void {
        this.add(message, 'warning', title);
    }

    info(message: string): void {
        this.add(message, 'info');
    }

    clear(): void {
        this.notifications = [];
    }

    getAll(): any[] {
        return this.notifications;
    }

    public titlelessError(notification): void {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            text: notification,
            showConfirmButton: true,
            confirmButtonText: 'Close',
            allowOutsideClick: false,
        });
    }

    public titlelessWarning(notification): void {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            text: notification,
            showConfirmButton: true,
            confirmButtonText: 'Close',
            allowOutsideClick: false,
        });
    }

    async confirm(notification): Promise<boolean> {
        let result;
        try {
            result = await Swal.fire({
                title: 'Are you sure?',
                text: notification,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#16a34a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Proceed',
            });
        } catch (e) {
            console.error('Error on confirmation dialog ', e);
        }
        return result.isConfirmed;
    }

    async confirmPayment(notification): Promise<boolean> {
        let result;
        try {
            result = await Swal.fire({
                title: 'Confirm',
                text: notification,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#16a34a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'confirm',
            });
        } catch (e) {
            console.error('Error on confirmation dialog ', e);
        }
        return result.isConfirmed;
    }

    async cookieAlert(): Promise<boolean> {
        let result;
        try {
            result = await Swal.fire({
                title: 'Help us protect you',
                text: 'Do you own or trust this device ? Select No if it is a public computer',
                showCancelButton: true,
                confirmButtonColor: '#16a34a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, I trust',
                cancelButtonText: 'No',
                allowOutsideClick: false,
            });
        } catch (e) {
            console.error('Error on confirmation dialog ', e);
        }
        return result.isConfirmed;
    }

    idleUser(notification) {
        let result;
        try {
            result = Swal.fire({
                text: notification,
                allowOutsideClick: false,
                imageUrl: '../../../assets/images/others/idle.gif',
                imageWidth: 150,
                imageHeight: 150,
                imageAlt: 'Custom image',
                confirmButtonColor: 'primary',
                confirmButtonText: 'Okay',

                showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                },
            }).then(() => {
                console.log('you clicked yes');
                // this._dashboardComponent.restart(); // this should execute now
            });
        } catch (e) {
            console.error('Error on confirmation dialog ', e);
        }
        return result.isConfirmed;
    }

    public toastNotification(notification, type) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            title: notification,
            icon: type,
        });
    }

    private showNotification(position, title, notification, type): void {
        Swal.fire({
            position: position,
            icon: type,
            title: StringUtils.titleCase(title),
            text: notification,
            showConfirmButton: true,
            confirmButtonText: 'Close',
        });
    }

    private showMessage(position, title, notification): void {
        Swal.fire({
            position: position,
            icon: 'info',
            title: StringUtils.titleCase(title),
            text: notification,
            showConfirmButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: ' Close',
        });
    }
}
