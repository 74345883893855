import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {FieldConfig} from '../../models/modelBank';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() field: FieldConfig;
  @Output() changed = new EventEmitter<any>();

    constructor() { }

  ngOnInit(): void {
  }

  onChange(event: any): void {
        this.changed.emit(event);
  }

}
