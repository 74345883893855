<div class="w-full">
    <mat-form-field class="w-full" [formGroup]="group">
        <mat-select [placeholder]="field.label" [formControlName]="field.name" (selectionChange)="onChange($event)"
                    [disabled]="field.disabled">
          <ng-container *ngIf="field.selectConfigs">
            <mat-option *ngFor="let item of field.selectConfigs.options" [value]="item[field.selectConfigs.valueKey]">{{item[field.selectConfigs.displayKey]}}</mat-option>
          </ng-container>
          <ng-container *ngIf="!field.selectConfigs">
            <mat-option *ngFor="let item of field.options" [value]="item">{{item}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
</div>
