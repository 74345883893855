import {TableConfig} from '../models/modelBank';

export class Constants {
    static DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

    static SESSION_KEYS = {
        USER: '_user',
        MENUS: '_menus',
        TAGS: '_tags',
    };

    static PAYMENT_MODEL_OPTIONS = ['OPEN', 'CLOSED'];
    static ID_TYPE_OPTIONS = ['NATIONAL', 'PASSPORT', 'OTHER'];
    static CLAWBACK_TYPES = ['NOT_APPLICABLE', 'AUTOMATIC', 'MANUAL'];

    static FILES_TABLE_CONFIGS: TableConfig = {
        datasource: [],
        datatable: 'ngx-datatable',
        columnsToDisplay: [
            {
                columnDef: 'businessName',
                columnText: 'Business Entity',
                type: 'string',
            },
            {
                columnDef: 'fileName',
                columnText: 'File Name',
                type: 'string',
            },
            {
                columnDef: 'totalRecords',
                columnText: 'Total Records',
                type: 'string',
            },
            {
                columnDef: 'successRecords',
                columnText: 'Successful',
                type: 'string',
            },
            {
                columnDef: 'failedRecords',
                columnText: 'Failed',
                type: 'string',
            },
            {
                columnDef: 'createdBy',
                columnText: 'Uploaded By',
                type: 'string',
            },
            {
                columnDef: 'requestTime',
                columnText: 'Time Initiated',
                type: 'date',
            },
            {
                columnDef: 'status',
                columnText: 'Status',
                type: 'string',
            },
            {
                columnDef: 'options',
                type: 'option_menu',
                menuOptions: {
                    menuItems: [
                        {
                            name: 'View',
                            unique_id: 'view',
                            icon: 'visibility',
                        },
                    ],
                },
            },
        ],
    };

    REPORT_TYPES = {
        CSV: 'CSV',
        EXCEL: 'XLS',
    };
    TABLE_STATE = {
        LOADING: 'LOADING',
        NOT_FOUND: 'NOT_FOUND',
        SHOW: 'SHOW',
        LOADING_MORE: 'LOADING_MORE',
    };
    BUTTON_STATE = {
        LOADING: 'LOADING',
        SHOW: 'SHOW',
    };
    PAGE_STATE = {
        LOADING: 'LOADING',
        SHOW: 'SHOW',
        NOT_FOUND: 'NOT_FOUND',
        ERROR: 'ERROR',
    };
    TOP_BAR = {
        LOADING: 'LOADING',
        HIDE: 'HIDE',
    };
    ABSTRACT = {
        LOADING: 'LOADING',
        ERROR: 'ERROR',
        READY: 'READY',
    };
}

export enum ForgotPasswordMode {
    FIRST_TIME = 'FIRST_TIME',
    USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum TransactionStatus {
    COMPLETED = 'COMPLETED', PENDING = 'PENDING', FAILED = 'FAILED'
}

export enum TransactionTypes {
    DEPOSIT = 'DEPOSIT', WITHDRAWAL = 'WITHDRAWAL', INTER_ACCOUNT_TRANSFER = 'INTER ACCOUNT TRANSFER', LOAN_APPLICATION = 'LOAN APPLICATION'
}

