import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/helpers/constants';
import { BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class LoadingService {
    constants = new Constants();
    states = {
        tableState: this.constants.TABLE_STATE,
        buttonState: this.constants.BUTTON_STATE,
        pageState: this.constants.PAGE_STATE,
        topLoadingBarState: this.constants.TOP_BAR,
        abstractState: this.constants.ABSTRACT,
    };
    csvDataIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    isLoadingBSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    primaryTableStateBSubject: BehaviorSubject<string> =
        new BehaviorSubject<string>(this.states.tableState.LOADING);
    primaryButtonStateBSubject: BehaviorSubject<string> =
        new BehaviorSubject<string>(this.states.buttonState.SHOW);
    primarySpinnerStateBSubject: BehaviorSubject<string> =
        new BehaviorSubject<string>(this.states.abstractState.READY);
    primaryPageStateBSubject: BehaviorSubject<string> =
        new BehaviorSubject<string>(this.states.pageState.LOADING);
    topLoadingBarStateBSubject: BehaviorSubject<string> =
        new BehaviorSubject<string>(this.states.topLoadingBarState.HIDE);

    topLoadingBar = {
        setIsLoading: () => {
            this.topLoadingBarStateBSubject.next(
                this.states.topLoadingBarState.LOADING
            );
        },
        setIsHidden: () => {
            this.topLoadingBarStateBSubject.next(
                this.states.topLoadingBarState.HIDE
            );
        },
    };

    primaryTable = {
        setIsLoading: () => {
            this.primaryTableStateBSubject.next(this.states.tableState.LOADING);
        },
        setIsReady: () => {
            this.primaryTableStateBSubject.next(this.states.tableState.SHOW);
        },
        setNotFound: () => {
            this.primaryTableStateBSubject.next(
                this.states.tableState.NOT_FOUND
            );
        },
        setIsLoadingMore: () => {
            this.primaryTableStateBSubject.next(
                this.states.tableState.LOADING_MORE
            );
        },
    };

    primaryButton = {
        setIsLoading: () => {
            this.primaryButtonStateBSubject.next(
                this.states.buttonState.LOADING
            );
        },
        setIsReady: () => {
            this.primaryButtonStateBSubject.next(this.states.buttonState.SHOW);
        },
    };

    spinner = {
        setIsLoading: () => {
            this.primarySpinnerStateBSubject.next(
                this.states.abstractState.LOADING
            );
        },
        setIsReady: () => {
            this.primarySpinnerStateBSubject.next(
                this.states.abstractState.READY
            );
        },
        setIsError: () => {
            this.primarySpinnerStateBSubject.next(
                this.states.abstractState.ERROR
            );
        },
    };

    ngxUiLoaderNotifier: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
        []
    );

    ngxUiLoader = {
        isRunning: [],
        start: (id: string) => {
            this.ngxUiLoader.isRunning.push(id);
            this.ngxUiLoaderNotifier.next(this.ngxUiLoader.isRunning);
            this.ngxLoaderService.startLoader(id);
        },
        stop: (id: string) => {
            this.ngxLoaderService.stopLoader(id);
            this.ngxUiLoader.isRunning = this.ngxUiLoader.isRunning.filter(
                (loaderId) => loaderId !== id
            );
            this.ngxUiLoaderNotifier.next(this.ngxUiLoader.isRunning);
        },
        isLoading: (id: string): boolean => {
            return this.ngxUiLoader.isRunning.indexOf(id) > -1;
        },
    };

    constructor(private ngxLoaderService: NgxUiLoaderService) {}

    setCsvDataIsLoading(value: boolean): void {
        this.csvDataIsLoading.next(value);
    }

    setIsLoading(value: boolean): void {
        this.isLoadingBSubject.next(value);
    }
}
