import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AlertsService } from './alerts.service';

@Injectable({
    providedIn: 'root',
})
export class disableLogsProd {
    constructor(
        private _alertsService: AlertsService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    disableConsoleInProduction(): void {
        if (environment.production) {
            console.log(
                '%c🚨Stop!',
                'color: red; font-size: 30px; font-weight: bold;'
            );
            console.log(
                '%cThis is a browser feature intended for developers only. If someone told you to copy and paste something here, it is a scam and will give them access to your banking account.',
                'color: black; font-size: 20px; font-weight: bold;'
            );
            console.log = function (): void {};
            console.debug = function (): void {};
            console.warn = function (): void {};
            console.info = function (): void {};
            console.error = function (): void {};
        }
    }

    //check if browser allow cookies
    cookieCheck() {
        if (navigator.cookieEnabled) {
            console.log('cookies are enabled');
        } else {
            console.log('cookies disabled');
            this._alertsService.warning(
                "Hi, you can't be able to use our services, try again later",
                'System Error'
            );
        }
    }

    //disable right click
    disableRightClick() {
        this.document.addEventListener('contextmenu', (event) =>
            event.preventDefault()
        );
    }
}
