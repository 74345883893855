import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as _moment from 'moment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { ExportColumns } from '../../shared/models/modelBanks';

const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const PDF_TYPE = 'application/pdf';
const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

@Injectable()
export class ExportService {
    public pdfStyles: any = {
        margin: { horizontal: 20 },
        bodyStyles: { valign: 'top' },
        styles: {
            fontSize: 48,
            overflow: 'linebreak',
            cellPadding: 1,
            columnWidth: 'auto',
        },
        columnStyles: { text: { columnWidth: 'wrap' } },
    };
    generatedDocPassword;

    constructor() {}

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        console.log('Log of Json Being Exported To Json', json);
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        saveAs(data, 'export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    public exportToCsv(
        rows: object[],
        fileName: string,
        columns?: string[]
    ): string {
        if (!rows || !rows.length) {
            return;
        }
        const separator = ',';
        const keys = Object.keys(rows[0]).filter((k) => {
            if (columns?.length) {
                return columns.includes(k);
            } else {
                return true;
            }
        });
        const csvContent =
            keys.join(separator) +
            '\n' +
            rows
                .map((row) => {
                    return keys
                        .map((k) => {
                            let cell =
                                row[k] === null || row[k] === undefined
                                    ? ''
                                    : row[k];
                            cell =
                                cell instanceof Date
                                    ? cell.toLocaleString()
                                    : cell.toString().replace(/"/g, '""');
                            if (cell.search(/("|,|\n)/g) >= 0) {
                                cell = `"${cell}"`;
                            }
                            return cell;
                        })
                        .join(separator);
                })
                .join('\n');
        this.saveAsCsvFile(csvContent, fileName);
    }

    private saveAsCsvFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: CSV_TYPE,
        });
        saveAs(data, 'export_' + new Date().getTime() + CSV_EXTENSION);
    }

    public exportAsPdf(json: any[], fileName: string): void {
        const blob = new Blob(json, { type: PDF_TYPE });
        const filenamesend = 'export_' + new Date().getTime() + PDF_EXTENSION;
        saveAs(blob, filenamesend);
    }

    exportToPDF(data: any[], columns: ExportColumns[], name: string): void {
        require('jspdf-autotable');
        Swal.fire({
            title: "Set Document Password",           
            html: `<input type="password" id="password" class="swal2-input" placeholder="Password">`,
            confirmButtonText: 'Save',
            focusConfirm: false,
        
            preConfirm: () => {
              const passwordElement = Swal.getPopup().querySelector('#password') as HTMLInputElement
    
              const password = passwordElement.value
              if (!password) {
                Swal.showValidationMessage(`Please enter password`)
              }else{
                const doc = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a0',
                    encryption: {
                      userPassword: password,
                      ownerPassword: 'P@ssw0rd',
                      userPermissions: ['print', 'modify', 'copy', 'annot-forms']
                    }
                  });
                  
                // @ts-ignore
                doc.autoTable(columns, data, this.pdfStyles);
                doc.save('statements_' + new Date().getTime() + PDF_EXTENSION);
              }
            }
            });

    }

    exportToPDFWithoutPwd(data: any[], columns: ExportColumns[], name: string): void {
    require('jspdf-autotable');
            const doc = new jsPDF({
                orientation: 'l',
                unit: 'mm',
                format: 'a0',
                });
            // @ts-ignore
            doc.autoTable(columns, data, this.pdfStyles);
            doc.save(name + new Date().getTime() + PDF_EXTENSION);
    }
    

        //Get Document Password
openDialog(){
 

    }

    extractNestedExportValues(dataForExport?) {
        console.log('data retrieved for export', dataForExport);
        const nestedColumns = dataForExport.columnsToDisplay.filter(
            (v) => !!v.nestedValue
        );
        let data: any[] = dataForExport.datasource;
        const dateColumns = dataForExport.columnsToDisplay.filter(
            (v) => v.type.toLocaleLowerCase() === 'date'
        );
        data = data.map((dataRecord, i) => {
            // formats dates appropriately
            dateColumns.forEach((dateCreated) => {
                console.log;
                dataRecord[dateCreated.columnDef] = _moment(
                    dataRecord[dateCreated.columnDef],
                    'x'
                ).format('dddd, MMMM Do YYYY, h:mm:ss a');
            });
            nestedColumns.forEach((column) => {
                dataRecord[
                    `${column.nestedValue.columnDef}.${column.nestedValue.key}`
                ] = dataRecord[column.nestedValue.columnDef]
                    ? dataRecord[column.nestedValue.columnDef][
                          column.nestedValue.key
                      ]
                    : 'null';
            });
            dataRecord['auto'] = i + 1;
            return dataRecord;
        });

        // Remove non-data columns eg buttons, checkboxes
        const columns = dataForExport.columnsToDisplay.filter(
            (column) =>
                !column.type ||
                column.type === 'string' ||
                column.type === 'date' ||
                column.type === 'auto'
        );
        const formattedColumns = columns.map((col) => {
            if (col.nestedValue) {
                return {
                    title: col.columnText
                        ? col.columnText
                        : col.nestedValue.columnDef,
                    dataKey: `${col.nestedValue.columnDef}.${col.nestedValue.key}`,
                };
            }
            return {
                title: col.columnText ? col.columnText : col.columnDef,
                dataKey: col.columnDef,
            };
        });
        const excelData = data.map((dataRecord) => {
            const record = {};
            formattedColumns.forEach((column) => {
                record[column.title] = dataRecord[column.dataKey];
            });
            return record;
        });
        return {
            pdfData: data,
            excelData: excelData,
            columns: formattedColumns,
        };
    }

    exportData(type: string, dataForExport?) {
        const fileName = type;
        const { columns, pdfData, excelData } =
            this.extractNestedExportValues(dataForExport);
        if (type === 'PDF') {
            this.exportToPDF(pdfData, columns, fileName);
            //  location.reload()
        } else if (type === 'EXCEL') {
            this.exportAsExcelFile(excelData, fileName);
            //   location.reload();
        }
    }

    exportDataWithoutPwd(type: string, dataForExport?) {
        const fileName = type;
        const { columns, pdfData, excelData } =
            this.extractNestedExportValues(dataForExport);
        // if (type === 'PDF') {
            this.exportToPDFWithoutPwd(pdfData, columns, fileName);
        // } else if (type === 'EXCEL') {
        //     this.exportAsExcelFile(excelData, fileName);
        //     //   location.reload();
        // }
    }
}
