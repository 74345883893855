import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [],
        canActivateChild: [],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'reset-account',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-account/reset-account.module'
                    ).then((m) => m.AuthResetAccountModule),
            },
            
            {
                path: 'agreements',
                loadChildren: () =>
                    import(
                        'app/modules/auth/agreements/agreements.module'
                    ).then((m) => m.AuthAgreementsModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'deactivation',
                loadChildren: () =>
                    import(
                        'app/modules/auth/deactivation/deactivation.module'
                    ).then((m) => m.DeactivationModule),
            },
            {
                path: 'accept-terms',
                loadChildren: () =>
                    import(
                        'app/modules/auth/accept-terms/accept-terms.module'
                    ).then((m) => m.AuthAcceptTermsModule),
            }
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'change-password',
                loadChildren: () =>
                    import('app/modules/auth/change-password/change-password.module').then(
                        (m) => m.AuthChangePasswordModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: 'landing',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('app/modules/admin/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'dash',
                loadChildren: () =>
                    import('app/modules/admin/alternate-dashboard/alternate-dashboard.module').then(
                        (m) => m.AlternateDashboardModule
                    ),
            },
            {
                path: 'personal-details',
                loadChildren: () =>
                    import('app/modules/admin/personal-details/personal-details.module').then(
                        (m) => m.PersonalDetailsModule
                    ),
            },
            {
                path: 'feedback',
                loadChildren: () =>
                    import('app/modules/admin/feedback/feedback.module').then(
                        (m) => m.FeedbackModule
                    ),
            },
            {
                path: 'transactions',
                loadChildren: () =>
                    import(
                        'app/modules/admin/transactions/transactions.module'
                    ).then((m) => m.TransactionsModule),
            },
         
            {
                path: 'referFriend',
                loadChildren: () =>
                    import(
                        'app/modules/admin/referFriend/referFriend.module'
                    ).then((m) => m.ReferFriendModule),
            },
     
            {
                path: 'enquiries',
                loadChildren: () =>
                    import('app/modules/admin/enquiries/enquiries.module').then(
                        (m) => m.EnquiriesModule
                    ),
            },
            {
                path: 'statements',
                loadChildren: () =>
                    import(
                        'app/modules/admin/statements/statements.module'
                    ).then((m) => m.StatementsModule),
            },
            {
                path: 'receipts',
                loadChildren: () =>
                    import('app/modules/admin/receipts/receipts.module').then(
                        (m) => m.ReceiptsModule
                    ),
            },
            {
                path: 'calculator',
                loadChildren: () =>
                    import(
                        'app/modules/admin/calculator/calculator.module'
                    ).then((m) => m.CalculatorModule),
            },
            {
                path: 'shareCertificate',
                loadChildren: () =>
                    import(
                        'app/modules/admin/shareCertificate/shareCertificate.module'
                    ).then((m) => m.CalculatorModule),
            },
            {
                path: 'loans',
                loadChildren: () =>
                    import('app/modules/admin/loans/loans.module').then(
                        (m) => m.LoansModule
                    ),
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
            {
                path: 'service',
                loadChildren: () =>
                    import('app/modules/admin/service/service.module').then(
                        (m) => m.ServiceModule
                    ),
            },
            {
                path: 'unsecuredLoans',
                loadChildren: () =>
                    import(
                        'app/modules/admin/unsecured-loan/unsecured-loan.module'
                    ).then((m) => m.UnsecuredLoanModule),
            },
            {
                path: 'guarantors',
                loadChildren: () =>
                    import(
                        'app/modules/admin/guarantors/guarantors.module'
                    ).then((m) => m.GuarantorsModule),
            },
            {
                path: 'myLoans',
                loadChildren: () =>
                    import(
                        'app/modules/admin/myloans/myloans.module'
                    ).then((m) => m.MyloansModule),
            },
        
        ],
    },
    {
        path: 'error-500',
        loadChildren: () =>
            import('app/modules/admin/error/error-500/error-500.module').then(
                (m) => m.Error500Module
            ),
    },
    // Error
    {
        path: 'error',
        children: [
            {
                path: '404',
                loadChildren: () =>
                    import(
                        'app/modules/admin/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            {
                path: '500',
                loadChildren: () =>
                    import(
                        'app/modules/admin/error/error-500/error-500.module'
                    ).then((m) => m.Error500Module),
            },
        ],
    },
];
