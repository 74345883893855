import * as CryptoJS from 'crypto-js';

export default class Cryptographer {
    static aes = {
        encrypt: () => {
        },
        decrypt: (encryptedText: string, secretKey: string): string => {
            secretKey = secretKey ? CryptoJS.enc.Utf8.parse(secretKey) : null;
            const decryptedData = CryptoJS.AES.decrypt(encryptedText, secretKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            return decryptedData.toString(CryptoJS.enc.Utf8);
        },
    };
    static custom = {
        decrypt: (encryptedText, length) => {
            console.log('-=====================>', encryptedText);
            const charIndexes = [78, 23, 97, 45, 12, 88, 50, 16, 90, 30, 27, 69, 11, 3, 81, 79, 46, 4, 51, 15, 62, 26, 75, 31];
            let decryptedText = '';
            for (let i = 0; i < length; i++) {
                const charIndex = charIndexes[i];
                console.log('>>>', charIndex, encryptedText.charAt(charIndex));
                decryptedText += encryptedText.charAt(charIndex);
            }
            return decryptedText;
        }
    };

    constructor() {
    }

    public static fromBase64(base64String: string): string {
        const decoded = CryptoJS.enc.Base64.parse(base64String);
        return decoded.toString(CryptoJS.enc.Utf8);
    }

    public static toBase64(text: string): string {
        return CryptoJS.enc.Base64.stringify(text);
    }

    public static decrypt(encryptedText: string, options: {
        aes?: { secretKey: string },
        custom?: { length: number }
    }) {
        if (options.aes) {
            return this.aes.decrypt(encryptedText, options.aes.secretKey);
        } else if (options.custom) {
            this.custom.decrypt(encryptedText, length);
        }
    }


}
