import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, Form} from '@angular/forms';
import { FieldConfig } from 'app/shared/models/modelBank';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})

export class InputComponent implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  @Output() changed = new EventEmitter<any>();

    constructor() { }

  ngOnInit() {
  }

  onChange(event: any): void {
        this.changed.emit(event);
  }

}
