/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigation: FuseNavigationItem[] = [
    // {
    //     id: 'calculator.select',
    //     title: 'Loan Calculator',
    //     type: 'basic',
    //     icon: 'heroicons_outline:calculator',
    //     link: '',
    // },
];
export const defaultNavigation: FuseNavigationItem[] = navigation;
export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
