import { ChangeDetectorRef, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiService } from '../api/api.service';
import { AlertsService } from './alerts.service';
import { LoadingService } from './loading.service';
import { AuthenticationService } from '@fuse/services/crypt-decrypt/authentication.service';

@Injectable()
export class UserProfileService {
    profilePicture: void;
    constructor(
        private _alertsService: AlertsService,
        private _apiService: ApiService,
        private _loaderService: LoadingService,
        private _changeDetector: ChangeDetectorRef,
        private authenticationService: AuthenticationService
    ) {}
    /**
     *   fetch profile picture
     */
    fetchUserProfile(): void {
        this._apiService
            .getWithEndpoint(environment.url.myDevices.getProfilePicture)
            .subscribe(
                (response) => {
                    console.log('profile response >>>', response);
                    this.profilePicture = response.entity.image;
                    this._changeDetector.detectChanges();
                },
                (error) => {
                    console.log('error >>>', error);
                }
            );
    }
}
