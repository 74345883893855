import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    SimpleChanges,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    HostListener,
    ViewEncapsulation,
} from '@angular/core';
import {
    ColumnConfigs,
    ExportConfig,
    TableConfig,
    PermissionHolder,
} from 'app/shared/models/modelBank';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from '../../helpers/constants';
import { LoadingService } from 'app/core/config/loading.service';
import { SelectionModel } from '@angular/cdk/collections';
// import { ExcelService } from '../../services/excel.service';
import { ExportColumns } from '../../models/modelBanks';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import {
    ColumnMode,
    DatatableComponent,
    RowHeightCache,
    SelectionType,
} from '@swimlane/ngx-datatable';
import { Utils } from '../../helpers/utils';
import { AlertsService } from 'app/core/config/alerts.service';

export interface ButtonActionPayload {
    buttonName: string;
    data: any;
}

@Component({
    selector: 'app-custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss'],
    providers: [DatePipe],
    encapsulation: ViewEncapsulation.None,
})
export class CustomTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(DatatableComponent, { static: true })
    ngxDatatable: DatatableComponent;
    @ViewChild('ngxDatatable', { static: false }) ngxDatatableV: any;

    @Input() tableConfig: TableConfig;
    @Input() exportConfig: ExportConfig;
    @Input() permissions: PermissionHolder;
    @Output() onButtonAction = new EventEmitter<ButtonActionPayload>();
    @Output() onMenuItemAction = new EventEmitter<any>();
    @Output() onSelectionAction = new EventEmitter<any[]>();
    @Output() onPaginationAction = new EventEmitter<any>();
    @Output() onClickRow = new EventEmitter<any>();
    @Output() onDblClickRow = new EventEmitter<any>();
    @Output() onExportAction = new EventEmitter<any>();
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    constants = new Constants();
    tableState: string;
    selection = new SelectionModel<any>(true, []);

    exportFor: string = null;

    stopWatchStart: any;

    ROW_HEIGHT_KEY = 'xdt_rowHeight';

    // ngx - datatable
    ColumnMode = ColumnMode;
    selected = [];
    SelectionType = SelectionType;
    ngxShowCheckBox = false;
    ngxTemp = [];
    divisionFactor = 15;
    expanded: any = {};

    // click || double click action variables
    preventSingleClick = false;
    clickTimer: any;
    scrHeight;
    scrWidth;

    constructor(
        private loadingService: LoadingService,
        // private excelService: ExcelService,
        private datePipe: DatePipe,
        private ns: AlertsService,
        private cd: ChangeDetectorRef
    ) {
        this.getScreenSize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.exportFor) {
            let data: any = null;
            data = changes.tableConfig
                ? changes.tableConfig.currentValue
                : data;
            if (
                !data.columnsToDisplay.some(
                    (column) => column.columnDef === 'auto'
                )
            ) {
                data.columnsToDisplay.unshift({
                    columnDef: 'auto',
                    type: 'auto',
                    columnText: 'AutoNumber',
                });
            }
            console.log('Export type', this.exportFor);
            // this.exportData(this.exportFor);
            this.loadingService.topLoadingBar.setIsHidden();
            this.exportFor = null;
        } else {
            this.tableConfig = changes.tableConfig
                ? changes.tableConfig.currentValue
                : this.tableConfig;
            if (
                !this.tableConfig.columnsToDisplay.some(
                    (column) => column.columnDef === 'auto'
                )
            ) {
                this.tableConfig.columnsToDisplay.unshift({
                    columnDef: 'auto',
                    type: 'auto',
                    columnText: 'AutoNumber',
                });
            }
            if (
                this.tableConfig.datatable === 'ngx-datatable' ||
                !this.tableConfig.datatable
            ) {
                this.addCompatibilityWithNgx();
            }
            this.updateTableConfigsPermissions(
                this.tableConfig,
                this.permissions
            );
            this.initializeMatTable();
        }
        this.loadingService.primaryButton.setIsReady();
    }

    ngOnInit() {
        this.stopWatchStart = moment();
        this.loadingService.primaryTableStateBSubject
            .asObservable()
            .subscribe((res) => (this.tableState = res));
    }

    ngxGetRowHeight(row) {
        const ROW_HEIGHT_KEY = 'xdt_rowHeight';
        return row ? row[ROW_HEIGHT_KEY] : 50;
    }

    addCompatibilityWithNgx() {
        // Has selection, show checkbox
        this.ngxShowCheckBox = this.tableConfig.columnsToDisplay.some(
            (column) => column.type === 'selection'
        );
        this.ngxTemp = [...this.tableConfig.datasource];
        this.tableConfig.datasource = this.tableConfig.datasource.map((row) =>
            this.addDynamicRowHeightSupport(row)
        );
        if (this.selected.length > 0) {
            this.selected = [];
        }
    }

    addDynamicRowHeightSupport(row: Object) {
        let defaultHeight = 60; // default
        let lineHeight = 20;
        let extraSpacing = this.tableConfig.extraSpacing | 0;
        let largest = this.tableConfig.columnsToDisplay
            .filter(
                (column) => column.type === 'string' || column.type === 'date'
            )
            .map((column) => {
                let value: string;
                if (column.nestedValue) {
                    value = row[column.nestedValue.columnDef]
                        ? row[column.nestedValue.columnDef][
                              column.nestedValue.key
                          ]
                        : 'null';
                } else {
                    value = row[column.columnDef]
                        ? row[column.columnDef].toString()
                        : '';
                }
                if (!value) {
                    value = '';
                }
                return value;
            })
            .map((value: string) =>
                Math.ceil(value.length / this.getFactorFromScreenSize())
            )
            .reduce((m, c) => (c > m ? c : m));
        row[this.ROW_HEIGHT_KEY] =
            (largest
                ? defaultHeight + (largest - 1) * lineHeight
                : defaultHeight) + extraSpacing;
        return row;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        this.divisionFactor = this.scrWidth;
        if (this.tableConfig) {
            this.tableConfig.datasource = this.tableConfig.datasource.map(
                (row) => this.addDynamicRowHeightSupport(row)
            );
            this.tableConfig.datasource = [...this.tableConfig.datasource];
        }
    }

    getFactorFromScreenSize() {
        if (this.scrWidth < 1000) {
            return 14;
        } else if (this.scrWidth < 1200) {
            return 10;
        } else if (this.scrWidth < 1500) {
            return 18;
        } else if (this.scrWidth < 1750) {
            return 20;
        } else {
            return 30;
        }
    }

    onSelectNgx({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        this.onSelectionAction.emit(this.selected);
    }

    ngxOnActivate(event) {
        if (event.type === 'dblclick') {
            this.onDblClickRow.emit(event.row);
        } else if (event.type === 'click') {
            this.onClickRow.emit(event.row);
        }
        return;
    }

    ngxGetRowClass(row) {
        return { 'ngx-row-bold': row.read_status && row.read_status === 0 };
    }

    ngxGetCellClass(cell) {
        return { ngxOptionMenu: true };
    }

    unCamelCase(value) {
        return Utils.unCamelCase(value);
    }

    getValue(value: any) {
        if (value && value.id) {
            return 'id: ' + value.id;
        } else {
            return value;
        }
    }

    getKeyCount(rows) {
        return Object.keys(rows).length;
    }

    ngxCalculateExpandedRowHeight(row) {
        if (row) {
            return Object.keys(row).length * 20;
        } else {
            return 200;
        }
    }

    toggleExpandRow(row) {
        console.log('Toggled Expand Row!', this.ngxDatatableV);
        console.log('Toggled Expand Row!', row);
        this.ngxDatatableV
            ? this.ngxDatatableV.rowDetail.toggleExpandRow(row)
            : () => {};
    }

    onDetailToggle(event) {
        console.log('Detail Toggled', event);
    }

    ngAfterViewInit(): void {
        // On a pagination action, emit to parent components
        this.paginator.page.subscribe((paginationData) => {
            this.onPaginationAction.emit(paginationData);
        });
    }

    ngxUpdateFilter(event) {
        const val: string = event.target.value.toLowerCase();
        let msisdnAlternative = null;
        if (val.startsWith('0')) {
            // probably a number search, create a number in the alternative format and search for both
            msisdnAlternative = val.replace('0', '254');
        }

        // get the key names of each column in the dataset
        let keys = this.tableConfig.columnsToDisplay
            .filter((column) => column.type === 'string' && !column.nestedValue)
            .map((column) => column.columnDef);

        let colsAmt = keys.length;
        // filter our data
        const temp = this.ngxTemp.filter(function (item) {
            // iterate through each row's column data
            for (let i = 0; i < colsAmt; i++) {
                // check for a match
                if (item[keys[i]] != null) {
                    if (
                        item[keys[i]].toString().toLowerCase().indexOf(val) !==
                            -1 ||
                        !val
                    ) {
                        // found match, return true to add to result set
                        return true;
                    } else if (keys[i] === 'msisdn' && msisdnAlternative) {
                        return (
                            item[keys[i]]
                                .toString()
                                .toLowerCase()
                                .indexOf(msisdnAlternative) !== -1
                        );
                    }
                }
            }
        });

        // update the rows
        this.tableConfig.datasource = temp;
        // Whenever the filter changes, always go back to the first page
        this.ngxDatatable ? (this.ngxDatatable.offset = 0) : this.ngxDatatable;
    }

    /**
     * Extends the capability of the normal string filter to searching nested values.
     */
    initiateFilter() {
        this.dataSource.filterPredicate = (data, filter: string) => {
            const nestedColumns: ColumnConfigs[] =
                this.tableConfig.columnsToDisplay.filter(
                    (columnConfig) => columnConfig.nestedValue
                );
            const nestedColumnsKeys = nestedColumns.map(
                (columnConfig) => columnConfig.columnDef
            );
            const accumulator = (currentTerm, key) => {
                if (nestedColumnsKeys.includes(key)) {
                    const thisColumnConfig = nestedColumns.filter(
                        (nc) => nc.columnDef === key
                    )[0];
                    return data[key]
                        ? currentTerm +
                              data[key][thisColumnConfig.nestedValue.key]
                        : currentTerm + data[key];
                } else {
                    return currentTerm + data[key];
                }
            };
            const dataStr = Object.keys(data)
                .reduce(accumulator, '')
                .toLowerCase();
            // Transform the filter by converting it to lowercase and removing whitespace.
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
    }

    onButtonClick(event, data: any) {
        const buttonActionPayload: ButtonActionPayload = {
            buttonName: event.target,
            data: data,
        };
        this.onButtonAction.emit(buttonActionPayload);
    }

    onMenuItemClick(uniqueId, data: any) {
        const menuActionPayload: any = {
            uniqueId: uniqueId,
            data: data,
        };
        this.onMenuItemAction.emit(menuActionPayload);
    }

    toNormalCase(data: string): string {
        // TODO Change Camel Case or any case to human readable case
        return data;
    }

    columnsToDisplayToArray(columnsToDisplay: ColumnConfigs[]) {
        const array = [];
        columnsToDisplay.forEach((v) => {
            array.push(v.columnDef);
        });
        return array;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
        this.onSelectionAction.emit(this.selection.selected);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
            row.position + 1
        }`;
    }

    onSelect(row) {
        this.selection.toggle(row);
        this.onSelectionAction.emit(this.selection.selected);
    }

    initializeMatTable() {
        this.initiateFilter();
        this.dataSource.data = this.tableConfig.datasource;
    }

    applyFilter(event: Event) {
        if (this.tableConfig.datatable === 'mat-table') {
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
        } else {
            this.ngxUpdateFilter(event);
        }
    }

    extractNestedValues(dataForExport?: any[]) {
        const nestedColumns: ColumnConfigs[] =
            this.tableConfig.columnsToDisplay.filter((v) => !!v.nestedValue);
        let data: any[] = dataForExport
            ? dataForExport
            : this.tableConfig.datasource;
        const dateColumns: ColumnConfigs[] =
            this.tableConfig.columnsToDisplay.filter(
                (v) => v.type.toLocaleLowerCase() === 'date'
            );
        data = data.map((dataRecord, i) => {
            // formats dates appropriately
            dateColumns.forEach((dateColumn) => {
                dataRecord[dateColumn.columnDef] = this.datePipe.transform(
                    dataRecord[dateColumn.columnDef]
                );
            });
            nestedColumns.forEach((column) => {
                dataRecord[
                    `${column.nestedValue.columnDef}.${column.nestedValue.key}`
                ] = dataRecord[column.nestedValue.columnDef]
                    ? dataRecord[column.nestedValue.columnDef][
                          column.nestedValue.key
                      ]
                    : 'null';
            });
            dataRecord['auto'] = i + 1;
            return dataRecord;
        });

        // Remove non-data columns eg buttons, checkboxes
        const columns = this.tableConfig.columnsToDisplay.filter(
            (column) =>
                !column.type ||
                column.type === 'string' ||
                column.type === 'date' ||
                column.type === 'auto'
        );
        const formattedColumns: ExportColumns[] = columns.map((col) => {
            if (col.nestedValue) {
                return {
                    title: col.columnText
                        ? col.columnText
                        : col.nestedValue.columnDef,
                    dataKey: `${col.nestedValue.columnDef}.${col.nestedValue.key}`,
                };
            }
            return {
                title: col.columnText ? col.columnText : col.columnDef,
                dataKey: col.columnDef,
            };
        });
        const excelData = data.map((dataRecord) => {
            const record = {};
            formattedColumns.forEach((column) => {
                record[column.title] = dataRecord[column.dataKey];
            });
            return record;
        });
        return {
            pdfData: data,
            excelData: excelData,
            columns: formattedColumns,
        };
    }

    emitExportRequest(type: string) {
        this.exportFor = type;
       // this.loadingService.topLoadingBar.setIsLoading();
        this.onExportAction.emit(type);
       // this.loadingService.primaryButton.setIsLoading();
        //this.ns.add('Download will start soon', 'info');
    }

    // exportData(type: string, dataForExport?: any[]) {
    //     const fileName = this.exportConfig ? this.exportConfig.fileName : 'Export';
    //     const { columns, pdfData, excelData } = this.extractNestedValues(dataForExport);
    //     if (type === 'PDF') {
    //         this.excelService.exportToPDF(pdfData, columns, fileName);
    //     } else if (type === 'EXCEL') {
    //         this.excelService.exportAsExcelFile(excelData, fileName);
    //     }
    // }

    clickRow(row) {
        this.preventSingleClick = false;
        const clickDelay = 300;
        this.clickTimer = setTimeout(() => {
            if (!this.preventSingleClick) {
                this.onClickRow.emit(row);
            }
        }, clickDelay);
    }

    dbClickRow(row) {
        this.onDblClickRow.emit(row);
        this.preventSingleClick = true;
        clearTimeout(this.clickTimer);
    }

    /**
     * Updates display field to hide or unhide menu Items according to the permissions given. Checks presence of
     * the menuItem permission against the permissions passed in. If a menuItem's defined requiredPermission is
     * not found, menuItem.display is set to false to hide from view.
     *
     * @param tableConfigs TableConfig
     * @param permissions PermissionHolder
     */
    updateTableConfigsPermissions(
        tableConfigs: TableConfig,
        permissions: PermissionHolder
    ) {
        permissions = !!permissions
            ? permissions
            : { add: false, update: false, delete: false };
        console.log('Permissions', permissions);

        // find options
        const optionsConfigs: ColumnConfigs =
            tableConfigs.columnsToDisplay.find(
                (columnDef) => columnDef.type === 'option_menu'
            );
        // find defined menu items -> Find menu Items that require permissions ->
        // conditionally hide or show in array acccording to permissions required
        if (!optionsConfigs) {
            console.log('NO OPTION MENUS');
            return;
        }
        optionsConfigs.menuOptions.menuItems
            .filter((item) => !!item.requiredPermission)
            .forEach(
                (protectedMenuItem) =>
                    (protectedMenuItem.hasPermission =
                        permissions[protectedMenuItem.requiredPermission])
            );

        // Default unset to true
        optionsConfigs.menuOptions.menuItems
            .filter((item) => !item.requiredPermission)
            .forEach(
                (unprotectedMenuItem) =>
                    (unprotectedMenuItem.hasPermission = true)
            );
        console.log('Table Permissions', optionsConfigs);
    }
}
