import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Utils } from '../../shared/helpers/utils';
import { catchError, filter, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { AlertsService } from '../config/alerts.service';
import CryptoJS from 'crypto-js';
import Cryptographer from 'app/shared/helpers/Cryptographer';
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    sessionEncryptionKey: any = localStorage.getItem("Thumbnail") ?? undefined;
    staticEncryptionKey:any = environment.secretKey
    constructor(
        private _httpClient: HttpClient,
        private _alertService: AlertsService,

    ) {
        
    }

    /**
     * Appends the endpoint to the base url
     * @param endpoint
     * @return string
     */
    resolveUrl(endpoint: string): string {
        return environment.url.base + endpoint;
    }

    /**
     * Get request to API
     * @param endpoint
     */
    get(endpoint: string): Observable<any> {
        return this._httpClient.get(
            this.resolveUrl(endpoint)
        ).pipe(
            map((response: any) => {
                // Decrypt the response body using the static encryption key
                const decryptedResponse = this.decryptData(response.hashedBody);
                
                // Parse the decrypted response body as JSON
                const parsedResponse = JSON.parse(decryptedResponse);
                
                // Return the parsed response
                return parsedResponse;
            }),
            catchError(error => {
                // Handle any errors that may occur during the request
                // Implement your error handling logic here
                return throwError(error);
            })
        );
    }

    /**
     * Get request to API
     * Transforms the query string object to a string and appends to the url
     * @param endpoint
     * @param queryStringObject An Object (will be converted to queryString)
     */
    getWithParams(endpoint: string, queryStringObject: any): Observable<any> {
        const queryParams = Utils.makeQueryString(queryStringObject);
        if (queryParams) {
            return this._httpClient.get(
                this.resolveUrl(endpoint) + queryParams
            );
        } else {
            return this._httpClient.get(this.resolveUrl(endpoint));
        }
    }

    /**
     * Get request to API
     * Transforms the query string object to a string and appends to the url
     * @param endpoint
     */
    getWithEndpoint(endpoint: string): Observable<any> {
        return this._httpClient.get(this.resolveUrl(endpoint));
      }

      decryptData(encryptedData: string): string {
        // TODO: Implement decryption logic here
        const parsedBase64Key = Cryptographer.fromBase64(this.sessionEncryptionKey);
        const decryptedData = Cryptographer.decrypt(encryptedData, {aes: {secretKey: parsedBase64Key}});     
        return decryptedData;
    }
      
        
    getWithPathVariable(
        endpoint: string,
        pathVariableObject: any
    ): Observable<any> {
        const queryParams = Utils.makeQueryString(pathVariableObject);
        if (queryParams) {
           return this._httpClient.get(
                this.resolveUrl(endpoint) + queryParams
            ).pipe(
                map((response: any) => {
                    // Decrypt the response body using the static encryption key
                    const decryptedResponse = this.decryptData(response.hashedBody);
                    
                    // Parse the decrypted response body as JSON
                    const parsedResponse = JSON.parse(decryptedResponse);
                    
                    // Return the parsed response
                    return parsedResponse;
                }),
                catchError(error => {
                    // Handle any errors that may occur during the request
                    // Implement your error handling logic here
                    return throwError(error);
                })
            );
        } else {
            return this._httpClient.get(
                this.resolveUrl(endpoint)
            ).pipe(
                map((response: any) => {
                    // Decrypt the response body using the static encryption key
                    const decryptedResponse = this.decryptData(response.hashedBody);
                    
                    // Parse the decrypted response body as JSON
                    const parsedResponse = JSON.parse(decryptedResponse);
                    
                    // Return the parsed response
                    return parsedResponse;
                }),
                catchError(error => {
                    // Handle any errors that may occur during the request
                    // Implement your error handling logic here
                    return throwError(error);
                })
            );

        }
    }

    /**
     * Get request to API
     * Transforms the query string object to a string and appends to the url
     * @param endpoint
     * @param queryStringObject An Object (will be converted to queryString)
     */
    getWithoutParams(endpoint: string): Observable<any> { 
    // Make the HTTP POST request with headers and encrypted data
    return this._httpClient.get(this.resolveUrl(endpoint)).pipe(
            map((response: any) => {
                // Decrypt the response body using the static encryption key
                const decryptedResponse = this.decryptData(response.hashedBody);
                
                // Parse the decrypted response body as JSON
                const parsedResponse = JSON.parse(decryptedResponse);
                
                // Return the parsed response
                return parsedResponse;
            }),
            catchError(error => {
                // Handle any errors that may occur during the request
                // Implement your error handling logic here
                return throwError(error);
            })
        );

    
    }

    /**
     * Get request to API
     * Transforms the query string object to a string and appends to the url
     * @param endpoint
     * @param queryStringObject An Object (will be converted to queryString)
     */
    getWithPath(endpoint: string, queryStringObject: any): Observable<any> {
        return this._httpClient.get(
            this.resolveUrl(endpoint) + queryStringObject.id
        );
    }

    /**
     * Post Request to API
     * @param endpoint String of the endpoint
     * @param data Object (Payload) being sent, leave null if none
     */
    post(endpoint: string, data: any): Observable<any> {
        //  console.log(this.resolveUrl(endpoint) + data.id);
        return this._httpClient.post(this.resolveUrl(endpoint), data);
    }



    postWithStaticEncryptionKey(endpoint: string, data: any): Observable<any> {
        //  console.log(this.resolveUrl(endpoint) + data.id);
       
    // Encrypt the request body using the static encryption key
    const encryptedData = this.encryptString(JSON.stringify(data), this.staticEncryptionKey).toString();
    
    // Set the encrypted data as the request body
    let requestPayload = {'hashedBody':encryptedData};
    
    // Make the HTTP POST request with headers and encrypted data
    return this._httpClient.post(this.resolveUrl(endpoint), requestPayload)
        .pipe(
            map((response: any) => {
                // Decrypt the response body using the static encryption key
                const decryptedResponse = this.decrypt(response.hashedBody, this.staticEncryptionKey);
                
                // Parse the decrypted response body as JSON
                const parsedResponse = JSON.parse(decryptedResponse);
                
                // Return the parsed response
                return parsedResponse;
            }),
            catchError(error => {
                // Handle any errors that may occur during the request
                // Implement your error handling logic here
                return throwError(error);
            })
        );

        
    }



    postWithHeadersStaticSecretKey(
        endpoint: string,
        data: any,
        options: any
    ): Observable<any> {
        //  console.log(this.resolveUrl(endpoint) + data.id);
    // Encrypt the request body using the static encryption key
    const encryptedData = this.encryptString(JSON.stringify(data), this.staticEncryptionKey).toString();
    
    // Set the encrypted data as the request body
    let requestPayload = {'hashedBody':encryptedData};
    
    // Make the HTTP POST request with headers and encrypted data
    return this._httpClient.post(this.resolveUrl(endpoint),requestPayload , options)
        .pipe(
            map((response: any) => {
                // Decrypt the response body using the static encryption key
                const decryptedResponse = this.decrypt(response.hashedBody, this.staticEncryptionKey);
                
                // Parse the decrypted response body as JSON
                const parsedResponse = JSON.parse(decryptedResponse);
                console.log("parsedResponse ### ",parsedResponse);
                
                // Return the parsed response
                return parsedResponse;
            }),
            catchError(error => {
                // Handle any errors that may occur during the request
                // Implement your error handling logic here
                return throwError(error);
            })
        );

    }

    /**
     * Post Request to API with params
     * @param endpoint String of the endpoint
     * @param data Object (Payload) being sent, leave null if none
     */
    postWithParams(
        endpoint: string,
        data: any,
        queryStringObject: any
    ): Observable<any> {
        const queryParams = Utils.makeQueryString(queryStringObject);
        let url = this.resolveUrl(endpoint) + data.id;
        if (queryParams) {
            url = url + queryParams;
        }
        console.log(url);
        return this._httpClient.post(url, data);
    }

    /**
     * Put Request to API with params
     * @param endpoint String of the endpoint
     * @param data Object (Payload) being sent, leave null if none
     */
    putWithParams(
        endpoint: string,
        data: any,
        queryStringObject: any
    ): Observable<any> {
        const queryParams = Utils.makeQueryString(queryStringObject);
        let url = this.resolveUrl(endpoint) + data.id;
        if (queryParams) {
            url = url + queryParams;
        }
        console.log(url);
        return this._httpClient.put(url, data);
    }

    /**
     * Post Request to API
     * @param endpoint String of the endpoint
     * @param data Object (Payload) being sent, leave null if none
     */
    delete(endpoint: string, data: any): Observable<any> {
        console.log(this.resolveUrl(endpoint) + data.id);
        return this._httpClient.delete(this.resolveUrl(endpoint) + data.id);
    }

    /**
     * Put request to API
     * @param endpoint
     * @param data
     */
    put(endpoint: string, data: any): Observable<any> {
        console.log(this.resolveUrl(endpoint) + data.id);
        return this._httpClient.put(this.resolveUrl(endpoint) + data.id, data);
    }

    /**
     * Fetches report for file uploads
     * @param loaderService
     * @param alertsService
     * @param data
     * @param pageProfile
     * @param requestParams
     * @param tableConfigs
     */

    upload(url: string, formData: FormData): Observable<any> {
        return this._httpClient
            .post(this.resolveUrl(url), formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                map((event) => {
                    // Pull data for progress reports to update progress bar
                    let response;
                    console.log('Event file upload -> ', event);
                    if (event.type === HttpEventType.UploadProgress) {
                    }
                    if (event.type === HttpEventType.Response) {
                        console.log('Upload completed');
                        response = event.body;
                        console.log('Response upload : ', response);
                    }
                    return event;
                }),
                // Only return the response event to the caller, no need to forward progress reports
                filter((response) => response.type === HttpEventType.Response)
            );
    }

    /**
     * Trigger Download
     * @param url
     * @param fileName
     * @param mimeType
     */
    downloadFile(url: string, fileName: string, mimeType: string): void {
        this._httpClient
            .get(this.resolveUrl(url), {
                responseType: 'blob',
            })
            .subscribe(
                (res) => {
                    const data: Blob = new Blob([res], {
                        type: mimeType,
                    });
                    saveAs(data, fileName);
                },
                (error) => {
                    Utils.createErrorAlert(
                        error,
                        this._alertService,
                        'Could not download file'
                    );
                }
            );
    }

      //Decrypt Responses
      decrypt(encryptedCipherText, encryptSecretKey) {
        let parsedBase64Key = null;
        let encryptedBase64Key = null;

            encryptedBase64Key = encryptSecretKey;
            
            
            //console.log('Secret key to be parsed ', environment.secretKey);
            parsedBase64Key = Cryptographer.fromBase64(encryptedBase64Key);
           // console.trace('Secret key to be parsed ', parsedBase64Key);
        
        console.log('Decrypting ... -> ', parsedBase64Key, encryptedCipherText);
        const decryptedText = Cryptographer.decrypt(encryptedCipherText, {aes: {secretKey: parsedBase64Key}});
        console.log('DecryptedText = ', decryptedText);
        return decryptedText;
    }



    encryptString(plaintext,encryptedKey) {
        let encryptedBase64Key = encryptedKey;

        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        var encryptedData = CryptoJS.AES.encrypt(plaintext, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        // console.log("encryptedData = " +  encryptedData);
        return encryptedData;
    }
}
