<div class="mx-auto p-2">
        <!-- Export Function -->
        <mat-toolbar class="w-full grid md:grid-cols-4 sm:grid-cols-2">
            <div class="flex flex-col md:flex-row w-full">
            </div>
            <span class="flex-1 sm:hidden md:flex"></span>
            <span class="flex-1 sm:hidden md:flex"></span>
            <span class="toolbar-actions w-full" *ngIf="tableConfig.datasource && tableConfig.datasource.length > 0">
                <button mat-raised-button class="w-full" color="primary" [matMenuTriggerFor]="menu"
                        [disabled]="(loadingService.primaryButtonStateBSubject | async) === constants.BUTTON_STATE.LOADING">
                    <div class="d-flex justify-content-center">
                        <span
                            [hidden]="(loadingService.primaryButtonStateBSubject | async) === constants.BUTTON_STATE.LOADING">Export</span>
                        <span
                            [hidden]="(loadingService.primaryButtonStateBSubject | async) === constants.BUTTON_STATE.SHOW">
                            <mat-spinner [diameter]="17"></mat-spinner>
                        </span>
                    </div>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="emitExportRequest('PDF')">
                        <span>PDF</span>
                    </button>
                </mat-menu>
            </span>
        </mat-toolbar>
        <!-- Export Function -->
    <!--    Loader-->
    <div
        class="container-fluid text-gray text-center"
        *ngIf="tableState === constants.TABLE_STATE.LOADING"
    >
        <mat-progress-bar [mode]="'buffer'"></mat-progress-bar>
        <div class="container-fluid text-gray text-center">Loading</div>
    </div>

    <!-- Table component -->
    <div *ngIf="tableConfig.datatable === 'mat-table'">
        <!-- mat table -->
        <table
            mat-table
            matSort
            [dataSource]="tableConfig.datasource"
            class="mat-elevation-z8"
            [hidden]="tableState !== constants.TABLE_STATE.SHOW"
            class="example-container"
        >
            <!-- AutoNumber-->
            <ng-container matColumnDef="auto">
                <th mat-header-cell *matHeaderCellDef style="color: black !important">Num.</th>
                <td mat-cell *matCellDef="let i = index">
                    {{
                        this.paginator.pageIndex == 0
                            ? i + 1
                            : 1 +
                              i +
                              this.paginator.pageIndex * this.paginator.pageSize
                    }}
                </td>
            </ng-container>

            <!--Column Creation-->
            <ng-container
                *ngFor="let columnToDisplay of tableConfig.columnsToDisplay"
            >
                <!-- selection -->
                <ng-container
                    *ngIf="columnToDisplay.type === 'selection'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                >
                    <th mat-header-cell *matHeaderCellDef style="color: black !important">
                        <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                                selection.hasValue() && !isAllSelected()
                            "
                            [aria-label]="checkboxLabel()"
                        >
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? onSelect(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!--If String column-->
                <ng-container
                    *ngIf="columnToDisplay.type === 'string'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                    [sticky]="columnToDisplay.sticky"
                >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black !important">
                        {{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : columnToDisplay.columnDef
                        }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="
                            columnToDisplay.nestedValue &&
                            columnToDisplay.nestedValue.toolTipText
                                ? columnToDisplay.nestedValue.toolTipText(
                                      element[
                                          columnToDisplay.nestedValue.columnDef
                                      ]
                                  )
                                : ''
                        "
                    >
                        <span class="mobile-label"></span>
                        <ng-container
                            *ngIf="
                                columnToDisplay.nestedValue &&
                                    columnToDisplay.nestedValue.key;
                                else notNestedTemplate
                            "
                        >
                            <span>{{
                                element[columnToDisplay.nestedValue.columnDef]
                                    ? element[
                                          columnToDisplay.nestedValue.columnDef
                                      ][columnToDisplay.nestedValue.key]
                                    : "null"
                            }}</span>
                        </ng-container>
                        <ng-template #notNestedTemplate>
                            <span
                                *ngIf="
                                    columnToDisplay.statusBadgeClass;
                                    else notBadge
                                "
                                [ngClass]="
                                    element[columnToDisplay.statusBadgeClass]
                                "
                                class="badge"
                                >{{ element[columnToDisplay.columnDef] }}</span
                            >
                            <ng-template #notBadge>
                                <span>{{
                                    element[columnToDisplay.columnDef]
                                }}</span>
                            </ng-template>
                        </ng-template>
                    </td>
                </ng-container>

                <!--If Date column-->
                <ng-container
                    *ngIf="columnToDisplay.type === 'date'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black !important">
                        {{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : columnToDisplay.columnDef
                        }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="mobile-label">{{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : columnToDisplay.columnDef
                        }}</span>
                        <ng-container
                            *ngIf="
                                columnToDisplay.nestedValue &&
                                    columnToDisplay.nestedValue.key;
                                else notNestedTemplate
                            "
                        >
                            <span>{{
                                element[columnToDisplay.nestedValue.columnDef]
                                    ? (element[
                                          columnToDisplay.nestedValue.columnDef
                                      ][columnToDisplay.nestedValue.key] | date)
                                    : "null"
                            }}</span>
                        </ng-container>
                        <ng-template #notNestedTemplate>
                            <span>{{
                                element[columnToDisplay.columnDef]
                                    | date: "medium"
                            }}</span>
                        </ng-template>
                    </td>
                </ng-container>
                <!--If Icon column -->
                <ng-container
                    *ngIf="columnToDisplay.type === 'icon'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                >
                    <th mat-header-cell *matHeaderCellDef style="color: black !important">
                        {{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : columnToDisplay.columnDef
                        }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="mobile-label">{{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : columnToDisplay.columnDef
                        }}</span>
                        <ng-container
                            *ngIf="
                                columnToDisplay.nestedValue &&
                                    columnToDisplay.nestedValue.key;
                                else notNestedTemplate
                            "
                        >
                            <span>{{
                                element[columnToDisplay.nestedValue.columnDef]
                                    ? (element[
                                          columnToDisplay.nestedValue.columnDef
                                      ][columnToDisplay.nestedValue.key] | date)
                                    : "null"
                            }}</span>
                        </ng-container>
                        <ng-template #notNestedTemplate>
                            <span>{{
                                element[columnToDisplay.columnDef] | date
                            }}</span>
                        </ng-template>
                    </td>
                </ng-container>

                <!--If Button column-->
                <ng-container
                    *ngIf="columnToDisplay.type === 'button'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black !important">
                        {{
                            columnToDisplay.columnText
                                ? columnToDisplay.columnText
                                : toNormalCase(columnToDisplay.columnDef)
                        }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="mobile-label">
                            {{
                                columnToDisplay.columnDef
                                    ? columnToDisplay.columnDef
                                    : columnToDisplay.columnText
                            }}
                        </span>
                        <button
                            class="btn btn-sm btn-outline-primary"
                            [disabled]="columnToDisplay.disabled"
                            *ngIf="element.status === 0"
                            (click)="onButtonClick($event, element)"
                        >
                            {{
                                columnToDisplay.columnText
                                    ? columnToDisplay.columnText
                                    : toNormalCase(columnToDisplay.columnDef)
                            }}
                        </button>
                    </td>
                </ng-container>
                <ng-container
                    *ngIf="columnToDisplay.type === 'option_menu'"
                    matColumnDef="{{ columnToDisplay.columnDef }}"
                    stickyEnd
                >
                    <th mat-header-cell *matHeaderCellDef style="color: black !important"></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            aria-label="menu button"
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                *ngFor="
                                    let menuItem of columnToDisplay.menuOptions
                                        .menuItems
                                "
                                mat-menu-item
                                (click)="
                                    onMenuItemClick(menuItem.unique_id, element)
                                "
                                [hidden]="
                                    menuItem.isHidden
                                        ? menuItem.isHidden(element)
                                        : menuItem.isHidden ||
                                          !menuItem.hasPermission
                                "
                            >
                                <mat-icon>{{ menuItem.icon }}</mat-icon>
                                <span>{{ menuItem.name }}</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="
                    columnsToDisplayToArray(tableConfig.columnsToDisplay)
                "
                style="color: black !important"
            ></tr>
            <tr
                mat-row
                *matRowDef="
                    let row;
                    columns: columnsToDisplayToArray(
                        tableConfig.columnsToDisplay
                    );
                    let i = index
                "
                [ngClass]="row.read_status === 0 ? 'bold' : ''"
                (click)="clickRow(row)"
                (dblclick)="dbClickRow(row)"
            ></tr>
        </table>
    </div>
    <ng-container
        *ngIf="
            (!tableConfig.datatable ||
                tableConfig.datatable === 'ngx-datatable') &&
            tableState === constants.TABLE_STATE.SHOW
        "
    >
        <!-- ngx datatable -->
        <ngx-datatable
            #ngxDatatable
            style="width: 100%; margin-top: 20px"
            class="bootstrap expandable material"
            [rows]="tableConfig.datasource"
            [columnMode]="ColumnMode.flex"
            [headerHeight]="70"
            [footerHeight]="false"
            [rowHeight]="60"
            [selected]="selected"
            [selectionType]="SelectionType.checkbox"
            [selectAllRowsOnPage]="false"
            (select)="onSelectNgx($event)"
            [scrollbarV]="false"
            (activate)="ngxOnActivate($event)"
            [rowClass]="ngxGetRowClass"
        >
            <!-- Row Detail Template -->
            <ngx-datatable-row-detail
                style="overflow-y: visible"
                #myDetailRow
                (toggle)="onDetailToggle($event)"
            >
                <ng-template
                    let-row="row"
                    let-expanded="expanded"
                    ngx-datatable-row-detail-template
                >
                    <div
                        class="row"
                        style="
                            height: 200px !important;
                            padding-left: 35px;
                            
                        "
                    >
                        <div
                            class="col-md-3"
                            *ngFor="let entry of row | keyvalue"
                        >
                            <div>
                                <strong style="font-weight: 900">{{
                                    unCamelCase(entry.key)
                                }}</strong>
                            </div>
                            <div class="datatable-body-cell">
                                {{ getValue(entry.value) }}
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="row.ngxTtype === 'user'">
                            <span>User Model</span>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column
                *ngIf="ngxShowCheckBox"
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"
            >
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'No.'" [flexGrow]="0.5">
                <ng-template
                    let-rowIndex="rowIndex"
                    class="text-xs md:text-sm lg:text-md"
                    let-row="row"
                    let-expanded="expanded"
                    ngx-datatable-cell-template
                >
                    <a
                        *ngIf="getKeyCount(row) > 7"
                        href="javascript:void(0)"
                        [class.datatable-icon-right]="!expanded"
                        [class.datatable-icon-down]="expanded"
                        title="Expand/Collapse Row"
                        (click)="toggleExpandRow(row)"
                    >
                    </a>
                    <span>{{
                        this.paginator.pageIndex == 0
                            ? rowIndex + 1
                            : 1 +
                              rowIndex +
                              this.paginator.pageIndex * this.paginator.pageSize
                    }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ng-container *ngFor="let column of tableConfig.columnsToDisplay">
                <ngx-datatable-column
                    class="text-xs md:text-sm lg:text-md"
                    *ngIf="column.type === 'string'"
                    [prop]="column.columnDef"
                    [name]="column.columnText"
                    [flexGrow]="column.flexGrow ? column.flexGrow : 1"
                >
                    <ng-template
                        let-value="value"
                        let-row="row"
                        ngx-datatable-cell-template
                    >
                        <ng-container
                            *ngIf="
                                column.nestedValue && column.nestedValue.key;
                                else notNestedTemplate
                            "
                        >
                            <span
                                *ngIf="
                                    column.nestedValue.nestedKey;
                                    else levelOne
                                "
                                >{{
                                    row[column.nestedValue.columnDef]
                                        ? row[column.nestedValue.columnDef][
                                              column.nestedValue.key
                                          ][column.nestedValue.nestedKey]
                                        : "null"
                                }}</span
                            >
                            <ng-template #levelOne>
                                <span>{{
                                    row[column.nestedValue.columnDef]
                                        ? row[column.nestedValue.columnDef][
                                              column.nestedValue.key
                                          ]
                                        : "null"
                                }}</span>
                            </ng-template>
                        </ng-container>
                        <ng-template #notNestedTemplate>
                            <span>{{ value }}</span>
                        </ng-template>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                    class="text-xs md:text-sm lg:text-md"
                    *ngIf="column.type === 'date'"
                    [prop]="column.columnDef"
                    [name]="column.columnText"
                    [flexGrow]="1"
                >
                    <ng-template
                        let-value="value"
                        let-row="row"
                        ngx-datatable-cell-template
                    >
                        <ng-container
                            *ngIf="
                                column.nestedValue && column.nestedValue.key;
                                else notNestedTemplate
                            "
                        >
                            <span>{{
                                row[column.nestedValue.columnDef]
                                    ? (row[column.nestedValue.columnDef][
                                          column.nestedValue.key
                                      ] | date)
                                    : "null"
                            }}</span>
                        </ng-container>
                        <ng-template #notNestedTemplate>
                            <span>{{ value | date: "d/M/yy, h:mm:ss a" }}</span>
                        </ng-template>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                    class="text-xs md:text-sm lg:text-md"
                    *ngIf="column.type === 'option_menu'"
                    [prop]="column.columnDef"
                    [name]="column.columnText"
                    [flexGrow]="1"
                    [cellClass]="ngxGetCellClass"
                >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            aria-label="menu button"
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                *ngFor="
                                    let menuItem of column.menuOptions.menuItems
                                "
                                mat-menu-item
                                (click)="
                                    onMenuItemClick(menuItem.unique_id, row)
                                "
                                [hidden]="
                                    menuItem.isHidden
                                        ? menuItem.isHidden(row)
                                        : menuItem.isHidden ||
                                          !menuItem.hasPermission
                                "
                            >
                                <mat-icon>{{ menuItem.icon }}</mat-icon>
                                <span>{{ menuItem.name }}</span>
                            </button>
                        </mat-menu>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
        </ngx-datatable>
    </ng-container>

    <!--    Pagination bar-->
    <div class="max-w-full">
        <mat-paginator
            class="w-full"
            [hidden]="
                tableState !== constants.TABLE_STATE.SHOW ||
                !tableConfig.pageProfile
            "
            [length]="
                tableConfig.pageProfile
                    ? tableConfig.pageProfile.totalElements
                    : tableConfig.datasource.length
            "
            [pageSize]="
                tableConfig.pageProfile ? tableConfig.pageProfile.pageSize : 10
            "
            [pageSizeOptions]="[10, 20, 30, 40, 50, 60]"
            showFirstLastButtons
        >
        </mat-paginator>
    </div>
    <!--    If data not found-->
    <div
        class="container-fluid text-gray text-center"
        *ngIf="tableState === constants.TABLE_STATE.NOT_FOUND"
    >
        <h3>No Data Found! :(</h3>
    </div>
    <!--    Pagination size change-->
    <div
        class="container-fluid text-gray text-center"
        *ngIf="tableState === constants.TABLE_STATE.LOADING_MORE"
    >
        <mat-progress-bar [mode]="'buffer'"></mat-progress-bar>
        <div class="container-fluid text-gray text-center">Loading</div>
    </div>
</div>      
