<form [formGroup]="myFormGroup" #formDirective="ngForm" (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-4 gap-4">
        <ng-container *ngFor="let form_elem of formTemplate">
            <ng-container [ngSwitch]="form_elem.type">
                <ng-container *ngSwitchCase="'input'">
                    <app-input [group]="myFormGroup" [field]="form_elem" [ngClass]="form_elem.class" (changed)="onInputChange($event)">
                    </app-input>
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                    <app-input [group]="myFormGroup" [field]="form_elem" [ngClass]="form_elem.class" (changed)="onInputChange($event)">
                    </app-input>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                    <app-select [group]="myFormGroup" [field]="form_elem" [ngClass]="form_elem.class" (changed)="onInputChange($event)">
                    </app-select>
                </ng-container>

                <ng-container *ngSwitchCase="'date'">
                    <app-date [group]="myFormGroup" [field]="form_elem" [ngClass]="form_elem.class" (changed)="onInputChange($event)">
                    </app-date>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="grid grid-cols-6 gap-4">
        <ng-container *ngFor="let form_elem of formTemplate">
            <ng-container [ngSwitch]="form_elem.type">
                <ng-container *ngSwitchCase="'button'">
                    <app-button [group]="myFormGroup" [field]="form_elem" [ngClass]="form_elem.class" (onClear)="onClearForm($event, formDirective)">
                    </app-button>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</form>
