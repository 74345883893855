<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-10 h-10 mr-4 rounded-full"
            src="{{
                profilePicture
                    ? profilePicture
                    : 'assets/images/others/temporary_profile.png'
            }}"
            alt="profile"
        />
    </span>
    <span
        class="absolute bottom-0 left-8 transform translate-y-1/4 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"
    ></span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{ fullName }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="callProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>My Account</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="callSignOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Log Out</span>
    </button>
    <!-- //addded button for password change -->
    <button mat-menu-item (click)="callchangepassword()">
        <mat-icon>lock</mat-icon>
        <span>Change password</span>
    </button>
    
      
</mat-menu>
