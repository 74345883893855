<h2 mat-dialog-title class="text-primary">{{_data.configuration?.title || 'Dialog' }}</h2>
<mat-dialog-content class="flex flex-col mat-typography relative">
    <h3 *ngIf="_data.configuration.subtitle" class="text-primary-500">{{_data.configuration.subtitle}}</h3>
    <!-- Loader -->
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <!-- Text Block -->
    <ng-container *ngIf="_data.configuration?.text">
        <p>{{_data.configuration.text}}</p>
    </ng-container>
    <!-- Datatable -->
    <ng-container *ngIf="_data.configuration && _data.configuration.formConfiguration">
        <div class="m-2">
            <app-filter-form [formConfigs]="_data.configuration.formConfiguration"
                             (formResult)="onFormResult($event)" [triggerSubmit]="triggerSubmit"></app-filter-form>
        </div>
    </ng-container>
    <!-- Details grid -->
    <ng-container *ngIf="_data.configuration.detailsGrid">
        <ng-container *ngFor="let section of _data.configuration.detailsGrid">
            <div class="grid grid-cols-{{section.columnNo}} gap-4">
                <div *ngFor="let element of section.elements" class="block group p-4 border-b" [ngClass]="element.colSpan ?  'col-span-'+element.colSpan : ''" >
                    <p class="font-bold text-lg mb-1 text-primary">{{element.label}}</p>
                    <p class="text-grey-darker mb-2">{{element.value}}</p>
                </div>
            </div>
            <mat-divider class="mt-2"></mat-divider>
        </ng-container>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions class="flex justify-between content-between">
    <div class="">
        <button mat-button color="warn" mat-dialog-close cdkFocusInitial>Close</button>
    </div>
    <div class="" *ngIf="_data.configuration.actionButtons && _data.configuration.actionButtons.length > 0">
        <button *ngFor="let buttonConfig of _data.configuration.actionButtons" mat-raised-button
                [color]="buttonConfig.color" [disabled]="isLoading" (click)="onClick(buttonConfig)">{{buttonConfig.value}}</button>
    </div>
</mat-dialog-actions>
