import { HttpClient } from '@angular/common/http';
import { Configuration } from './config';
import { catchError, filter, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Utils } from 'app/shared/helpers/utils';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';



@Injectable()
export class ConfigService {
  private config:typeof Configuration;
  domain: any;
  envConfig: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient
    ) {}
  async load() {
        // get domain from url
        this.domain = this.document.location.hostname;
        console.log(this.domain);
        //split domain and get the first keyword in the array
        const splt = this.domain.split('.');

        const rand = Utils.generateRandomString(3);
        const res = await this.http
            .get<any>('assets/' + splt[0] + '.json?r=' + rand)
            .toPromise();
        console.log(
            'json file path >>>',
            'assets/' + this.domain + '.json?r=' + rand
        );
        this.envConfig = res;
        Object.keys(this.envConfig).forEach((k) => {
            this.copySourceToDestination(this.envConfig, environment);
        });
  }

  getConfiguration(): typeof Configuration {
     return this.config;
  }

  copySourceToDestination(source: any, destination: any): void {
    Object.keys(source).forEach((k) => {
        //  console.log('override >>>', k, typeof k);
        if (typeof source[k] === 'object' && source[k] !== null) {
            this.copySourceToDestination(source[k], destination[k]);
        } else {
            destination[k] = source[k];
        }
    });
}
}
