import { Injectable } from '@angular/core';
import Cryptographer from 'app/shared/helpers/Cryptographer';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    encryptedData: null;
    constructor() {}

    //encrypt JSON
    encryptJSON(jsonData) {
        var encryptedBase64Key = environment.secretKey;
        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        var encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(jsonData),
            parsedBase64Key,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        console.log('encryptedData = ' + encryptedData);
        return encryptedData;
    }

    //encrypt string
    encryptString(plaintext) {
        var encryptedBase64Key = environment.secretKey;
        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        var encryptedData = CryptoJS.AES.encrypt(plaintext, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        //console.log('secretKey = ', encryptedBase64Key);
        return encryptedData;
    }

    //decrypt
    decryptWithSessionKey(
        encryptedCipherText,
        options?: { base64SecretKey?: string; secretKey?: string }
    ) {
        let parsedBase64Key = null;
        let encryptedBase64Key = null;
        if (options && options.secretKey) {
            parsedBase64Key = options.secretKey;
        } else if (options && options.base64SecretKey) {
            encryptedBase64Key = options.base64SecretKey;
            parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        } else {
            encryptedBase64Key = localStorage.getItem("Thumbnail") ?? undefined;
            console.log('Secret key to be parsed ', environment.secretKey);
            parsedBase64Key = Cryptographer.fromBase64(encryptedBase64Key);
            console.trace('Secret key to be parsed ', parsedBase64Key);
        }
        console.log('Decrypting >>> ', parsedBase64Key, encryptedCipherText);
        const decryptedText = Cryptographer.decrypt(encryptedCipherText, {
            aes: { secretKey: parsedBase64Key },
        });
        console.log('DecryptedText >>>', decryptedText);
        return decryptedText;
    }

     //encrypt string
     encryptWithSessionKey(plaintext) {
        var encryptedBase64Key = localStorage.getItem("Thumbnail") ?? undefined;
        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        var encryptedData = CryptoJS.AES.encrypt(plaintext, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        //console.log('secretKey = ', encryptedBase64Key);
        return encryptedData;
    }
}
