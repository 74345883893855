import { BooleanInput } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@fuse/services/crypt-decrypt/authentication.service';
import { ApiService } from 'app/core/api/api.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AlertsService } from 'app/core/config/alerts.service';
import { LoadingService } from 'app/core/config/loading.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'userMenu',
})
export class UserMenuComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;

    @Input() showAvatar: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    fullName: string;
    profilePicture: String;

    //private $profilePicture: BehaviorSubject<profilePicture> = new BehaviorSubject<Role[]>(this.profilePicture);

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _alertsService: AlertsService,
        private _apiService: ApiService,
        private _loaderService: LoadingService,
        private _changeDetector: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private authService: AuthService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        //call username
        this.getusername();
        //call profile
        this.fetchProfilePicture();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get username from local storage
     */
    private getusername() {
        this.fullName = localStorage.getItem('USERNAME').toString();
    }

    /**
     * navigate to my account/profile page
     */
    callProfile() {
        let route = this._activatedRoute;
        this._router.navigate(['../profile'], {
            relativeTo: route,
        });
    }

    callSignOut() {
        this.authService.signOut();
        this._router.navigate(['/sign-in']);
    }
    callchangepassword() {
        // Navigate to the change-password route
        this._router.navigate(['/change-password']);
      }
    
    /**
     *   fetch profile picture
     */

    fetchProfilePicture(): void {
        this._apiService
            .getWithEndpoint(environment.url.myDevices.getProfilePicture)
            .subscribe(
                (rawResponse) => {
                    const response = JSON.parse(
                        this.authenticationService.decryptWithSessionKey(
                            rawResponse['hashedBody']
                        )
                    );
                    console.log('profile response >>>', response);
                    this.profilePicture = response.entity.image;
                    this._changeDetector.detectChanges();
                },
                (error) => {
                    console.log('error >>>', error);
                }
            );
    }
}
