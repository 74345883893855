<!-- Messages toggle -->
<button mat-icon-button (click)="openPanel()" #messagesOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="">
            <span class="">
                {{ unreadCount }}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel> </ng-template>
