import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { FieldConfig } from 'app/shared/models/modelBank';

@Component({
    selector: 'app-check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {
    @Input() field: FieldConfig;
    @Input() group: FormGroup;
    @Output() changed = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Emits a change event to notify the parent form when the input has changed
     * @param event
     */
    onChange(event: any): void {
        this.changed.emit(event);
    }



}
