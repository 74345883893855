// text-slider.component.ts
import { Component, Input, OnInit, SimpleChanges,NgZone, ChangeDetectorRef  } from '@angular/core';

@Component({
  selector: 'app-text-slider',
  templateUrl: './text-slider.component.html',
  styleUrls: ['./text-slider.component.scss']
})
export class TextSliderComponent implements OnInit {
  
  @Input() messages: any[] = [];
  messagesArray: any[] = [];
  currentIndex = 0;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.messagesArray = this.messages;
    this.startSlider();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages && changes.messages.currentValue) {
      this.messagesArray = changes.messages.currentValue;
      this.currentIndex = 0;
      this.cdr.detectChanges();
    }
  }

  startSlider() {
    setInterval(() => {

        if (this.messagesArray.length > 0) {
          this.currentIndex = (this.currentIndex + 1) % this.messagesArray.length;
          this.cdr.detectChanges();
        }
    
    }, 5000); // Adjust the interval as needed
  }

}
