<div
    class="margin-top w-full items-start justify-start"
    [formGroup]="group"
    [ngClass]="field.class ? field.class : 'items-start justify-start'"
>
    <button
        class="w-full {{ field.buttonClass ? field.buttonClass : '' }}"
        mat-flat-button
        [color]="field.formButtonType ? '' : 'primary'"
        [type]="field.formButtonType ? field.formButtonType : 'submit'"
        (click)="field.formButtonType === 'reset' ? clear() : ''"
        [disabled]="group.invalid"
    >
        <mat-icon
            [svgIcon]="
                field.formButtonType
                    ? 'mat_outline:clear'
                    : 'mat_outline:search'
            "
        ></mat-icon>
        <span class="ml-2 mr-1">{{ field.label }}</span>
    </button>
</div>
