import { FieldConfig, PageProfile, TableConfig } from '../models/modelBank';
import { BasicRequestParams } from '../models/modelBanks';
import { Constants } from './constants';
import * as _moment from 'moment';
import { AlertsService } from '../../core/config/alerts.service';

export class Utils {
    /**
     * Generates a random string of a length passed to it
     * @param length
     */
    public static generateRandomString(length): string {
        const result = [];
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result.push(
                characters.charAt(Math.floor(Math.random() * charactersLength))
            );
        }
        return result.join('');
    }

    public static YES_OR_NO = {
        '0': 'NO',
        '1': 'YES',
    };

    public static makePathVariable() {
        const map = { id: 61 };
        var url = 'http://197.232.25.76:8086/dms/users/delete/';
        Object.keys(map).forEach((_key) => {
            url = url.replace('{' + _key + '}', map[_key]);
        });
        return url;
    }

    public static makeQueryString(params): string {
        let queryString = '?';
        let counter = 0;
        if (params) {
            for (const key of Object.keys(params)) {
                if (params[key] && counter < 1) {
                    queryString +=
                        key.replace('$', '.').replace('_d_2', '') +
                        '=' +
                        params[key];
                    ++counter;
                } else if (params[key] && counter >= 1) {
                    queryString +=
                        '&' +
                        key.replace('$', '.').replace('_d_2', '') +
                        '=' +
                        params[key];
                } else if (params[key] !== null || params[key] === null) {
                    queryString += '';
                }
            }
        } else {
            return '';
        }
        return queryString;
    }

    public static getOptionArrayFromEnum(enumValue: any): any[] {
        const returnArray: any[] = [];
        Object.keys(enumValue).forEach((v) => {
            returnArray.push({ value: v });
        });
        return returnArray;
    }

    public static extractPaginationData(
        page: PageProfile,
        pageProfile: PageProfile
    ): PageProfile {
        if (pageProfile) {
            pageProfile.totalPages = page.totalPages;
            pageProfile.last = page.last;
            pageProfile.first = page.first;
            pageProfile.totalElements = page.totalElements;
        } else {
            console.error('Pagination data not found!');
        }
        return pageProfile;
    }

    public static getJavaDateFormat(momentDate: string): string {
        return momentDate.replace('YYYY', 'yyyy').replace('DD', 'dd');
    }

    public static applyFormResultToRequestParams<T extends BasicRequestParams>(
        formResult: any,
        pageRequestParams: T
    ): T {
        Object.keys(formResult).forEach((key) => {
            // Unset any previous values -> important if a constraint was reset
            delete pageRequestParams[key];
            if (formResult[key] instanceof _moment) {
                // Date -> Transform to string
                pageRequestParams[key] = formResult[key].format(
                    Constants.DATE_FORMAT
                );
                // For dates that have constraint 'less than', set the time as EOD
                if (key.indexOf('lt_x') > -1) {
                    pageRequestParams[key] = _moment(pageRequestParams[key])
                        .endOf('day')
                        .format(Constants.DATE_FORMAT);
                }
                // Whenever a date constraint is set, dateFaormat should always accompany it -> check if present, add if not
                if (!pageRequestParams.dateFormat) {
                    pageRequestParams.dateFormat = this.getJavaDateFormat(
                        Constants.DATE_FORMAT
                    );
                }
            } else if (formResult[key]) {
                // Not date -> copy as is
                pageRequestParams[key] = formResult[key];
            } else {
                console.log('KEY UNSET >> ' + key + ':', formResult[key]);
            }
        });
        return pageRequestParams;
    }

    /**
     * changes a status map to an array with keys : code, name
     * @param map any
     * @return array
     */
    public static getArrayFromMap(map: Object) {
        const keys = Object.keys(map);
        let array: any[] = [];
        keys.forEach((key) => {
            array.push({ name: map[key], code: key });
        });
        return array;
    }

    public static unCamelCase(str): string {
        str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
        str = str.toLowerCase(); // add space between camelCase text
        return str;
    }

    /**
     * Checks if responseCode from API is '00' or 200
     * @param apiResponse
     */
    public static responseIsSuccess(apiResponse: any): boolean {
        return (
            (apiResponse?.responseCode && apiResponse.responseCode === '00') ||
            apiResponse?.code?.toString().startsWith('2') ||
            apiResponse?.status?.toString().startsWith('2') ||
            apiResponse?.statusCode?.toString().startsWith('2')
        );
    }

    /**
     * Checks if responseCode from API 404
     * @param apiResponse
     */
    public static responseIsNotFound(apiResponse: any): boolean {
        return (
            apiResponse?.code?.toString() === '404' ||
            apiResponse?.statusCode?.toString() === '404'
        );
    }

    /**
     * This utility function Creates an error alert.
     * Avoids writing duplicate logic across multiple components
     * @param response
     * @param alertService
     * @param defaultMessage Optional
     */
    public static createErrorAlert(
        response: any,
        alertService: AlertsService,
        // defaultMessage?: string,
        return_message?: string
    ): void {
        /* TODO Implementation has to change from apigateway to have a standard format. This way of checking for
         error messages is not ideal */
        const errorMessage = this.getErrorMessage(response, return_message);
        alertService.titlelessError(errorMessage);
    }

    public static createWarningAlert(
        response: any,
        alertService: AlertsService,
        // defaultMessage?: string,
        return_message?: string
    ): void {
        /* TODO Implementation has to change from apigateway to have a standard format. This way of checking for
         error messages is not ideal */
        const errorMessage = this.getErrorMessage(response, return_message);
        alertService.titlelessWarning(errorMessage);
    }

    // public static getErrorMessage(
    //     response: any,
    //     defaultMessage?: string
    // ): string {
    //     return response?.return_message
    //         ? response.return_message
    //         : response?.message
    //         ? response.message
    //         : response.header?.sd
    //         ? response.header.sd
    //         : defaultMessage
    //         ? defaultMessage
    //         : 'Could not complete request';
    // }


    public static getErrorMessage(
        response: any,
        defaultMessage?: string
    ): string {
        return response?.return_message 
            ? response.return_message 
            : response?.message 
            ? response.message 
            : response?.narration
            ? response.narration
            : response?.header?.sd 
            ? response.header.sd 
            : defaultMessage 
            ? defaultMessage 
            : 'Could not complete request';
    }
    

    /**
     * Shared function for updating tableconfigs and creating a new object to
     * trigger change detection
     *
     * @param datasource
     * @param tableConfig
     * @param pageProfile
     */
    public static updateTableConfigs(
        datasource: any[],
        tableConfig: TableConfig,
        pageProfile?: PageProfile
    ): TableConfig {
        tableConfig.datasource =
            datasource && datasource.length > 0 ? datasource : [];
        tableConfig.pageProfile = pageProfile
            ? pageProfile
            : tableConfig.pageProfile;
        tableConfig = Object.assign({}, tableConfig);
        console.log("This is the table config >>>", tableConfig)
        return tableConfig;
    }

    public static createFilterParams(
        formData: any,
        fieldConfigs: FieldConfig[]
    ): string {
        const payload: any = {
            likeFields: [],
            betweenDates: [],
        };
        console.log('Field Configs -> ', formData);
        fieldConfigs.forEach((fieldConfig) => {
            if (fieldConfig.type === 'input' || fieldConfig.type === 'select') {
                payload.likeFields.push({
                    [fieldConfig.name]: formData[fieldConfig.name],
                });
            } else if (fieldConfig.type === 'date') {
                const filterKey = fieldConfig.dateConfigs.fieldName
                    ? fieldConfig.dateConfigs.fieldName
                    : fieldConfig.name;
                const thisDate = payload.betweenDates.find(
                    (v, i) => v[filterKey]
                );
                if (thisDate) {
                    if (fieldConfig.dateContext === 'start') {
                        thisDate[filterKey].start = formData[fieldConfig.name];
                    } else if (fieldConfig.dateContext === 'end') {
                        thisDate[filterKey].end = formData[fieldConfig.name];
                    }
                } else {
                    payload.betweenDates.push({
                        [filterKey]: {
                            start:
                                fieldConfig.dateContext === 'start'
                                    ? formData[fieldConfig.name]
                                    : null,
                            end:
                                fieldConfig.dateContext === 'end'
                                    ? formData[fieldConfig.name]
                                    : null,
                        },
                    });
                }
            }
        });
        return encodeURIComponent(JSON.stringify(payload));
    }

    public static removeDuplicates(arr, equals) {
        var originalArr = arr.slice(0);
        var i, len, val;
        arr.length = 0;

        for (i = 0, len = originalArr.length; i < len; ++i) {
            val = originalArr[i];
            if (
                !arr.some(function (item) {
                    return equals(item, val);
                })
            ) {
                arr.push(val);
            }
        }
    }
}
