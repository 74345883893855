import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { ConfigService } from './core/config/config.service';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { FormlyModule } from '@ngx-formly/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { ColorPickerModule } from 'ngx-color-picker';
import { MarkdownModule } from 'ngx-markdown';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { LoadingService } from './core/config/loading.service';
import { UserIdleModule } from 'angular-user-idle';
import { ExportService } from './core/export/export.service';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { MatPaginatorModule } from '@angular/material/paginator';




const routerConfig: ExtraOptions = {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
};

export function ConfigLoader(configService: ConfigService) {
    return () => configService.load();
  }

  
@NgModule({
    declarations: [AppComponent, ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MatFormFieldModule,
        FormsModule,
        MatIconModule,
        MatPaginatorModule,

        // Fuse & Fuse Mock API
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),
        NgxUiLoaderModule,
        SweetAlert2Module.forRoot({}),
        ColorPickerModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({ extras: { lazyRender: true } }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('_authToken'),
            },
            
        }),
        //check for user inactivity
        /* Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
     Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
     and `ping` is 120 (2 minutes)
    */
        UserIdleModule.forRoot({
            idle: environment.sessionTimeout.idle, // Use value from environment file
            timeout: environment.sessionTimeout.timeout, // Use value from environment file
            ping: environment.sessionTimeout.ping,
        }),

    ],
    providers: [
        LoadingService,
        ExportService,
        AuthInterceptor,
        ConfigService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigLoader,
            deps: [ConfigService],
            multi: true
          },
    ],
    bootstrap: [AppComponent],
    
})
export class AppModule {}
