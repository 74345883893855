import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { EventType } from 'app/shared/enums/event.type.enum';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsService } from '../config/alerts.service';
import { EventsService } from '../config/events.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    noAuthUrl: string[] = ['/validateotp'];
    found: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _eventService: EventsService,
        private _alertsService: AlertsService,
        private _router: Router
    ) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!window.navigator.onLine) {
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            this._alertsService.toastNotification(
                'Internet Disconnected',
                'warning'
            );
            console.log('offline');
            return Observable.throw(
                new HttpErrorResponse({ error: 'Internet is required.' })
            );
        }
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (
            this._authService.accessToken &&
            !AuthUtils.isTokenExpired(this._authService.accessToken)
        ) {
            newReq = req.clone({
                headers: req.headers.set(
                    'ULINZI',
                    'Bearer ' + this._authService.accessToken
                ),
            });
        }

        //Handle Response error
        return next.handle(newReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    // Catch "401 Unauthorized" responses
                    if (
                        error instanceof HttpErrorResponse &&
                        error.status === 401
                    ) {
                        // Sign out
                        this._eventService.publish({
                            type: EventType.LOGOUT,
                        });
                        this._alertsService.error(
                            'Session expired, login again'
                        );
                        this._router.navigate(['/sign-in']);
                    } else {
                        this._alertsService.error(
                            'server Unreachable. Try again later'
                        );
                    }
                } else {
                    this._alertsService.error(error.message);
                }
                return throwError(error);
            })
        );
    }
}
