import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActionButtonConfiguration, CallbackDataModel, CallbackReturnModel} from '../../models/callbackData.model';
import {AlertsService} from '../../../core/config/alerts.service';
import {ApiService} from '../../../core/api/api.service';
import {LoadingService} from '../../../core/config/loading.service';
import {ActivatedRoute, Router} from '@angular/router';

export enum Defaults {
    WIDTH = '1050px'
}

@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
    loaderId = 'genericLoader';
    isLoading = false;
    triggerSubmit = false;

    constructor(public dialogRef: MatDialogRef<GenericDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public _data: CallbackDataModel,
                private _dialog: MatDialog,
                private _alertsService: AlertsService,
                private _apiService: ApiService,
                private _loadingService: LoadingService,
                private _router: Router,
                private _route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this._loadingService.ngxUiLoaderNotifier.subscribe((res: any[]) => {
            this.isLoading = this._loadingService.ngxUiLoader.isLoading(this.loaderId);
        });

        // run onLoad function if was provided
        if (this._data.onLoad) {
            this._data.onLoad({
                data: this._data.data,
                apiService: this._apiService,
                alertService: this._alertsService,
                loaderService: this._loadingService,
                loaderId: this.loaderId,
                url: null,
                router: this._router,
                dialogRef: this.dialogRef
            }).then((result) => {
                console.log('Result from fetch by id function', result);
            }).catch((err) => {
                console.log('Error ', err);
            });
        }
    }

    onFormResult(result): void {
        this._data.data.formResult = result;
        const callbackResult: CallbackReturnModel = this._data.callback({
            data: this._data.data,
            apiService: this._apiService,
            alertService: this._alertsService,
            loaderService: this._loadingService,
            loaderId: this.loaderId,
            url: null,
            router: this._router,
            dialogRef: this.dialogRef
        });
    }

    /**
     * Fires the callback function attached to a button providing the generic parameters for a shared
     * callback function.
     * @param actionButtonConfiguration
     */
    async onClick(actionButtonConfiguration: ActionButtonConfiguration): Promise<void> {
        console.log('Action Button response => ', actionButtonConfiguration);
        if (actionButtonConfiguration.isSubmitForm) {
            this.triggerSubmit = true;
            this._data.data.submittedBy = actionButtonConfiguration.buttonId;
        } else {
            const callbackResult: CallbackReturnModel = await actionButtonConfiguration.onClick({
                data: this._data.data,
                apiService: this._apiService,
                alertService: this._alertsService,
                loaderService: this._loadingService,
                loaderId: this.loaderId,
                url: null,
                router: this._router,
                dialogRef: this.dialogRef
            });
            console.log('Action Button Callback result ', callbackResult);
        }

    }

}
