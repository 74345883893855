export class StringUtils {
    /**
     * Add space between camelCase text.
     */
    constructor() {}

    public static unCamelCase(str: string): string {
        str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
        str = str.toLowerCase(); // add space between camelCase text
        return str;
    }

    /**
     * Converts text to title case
     * @param text
     */
    public static titleCase(text: string): string {
        const sentence = text.toLowerCase().split(' ');
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence.join(' ');
    }
}
