import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import {
    FuseNavigationItem,
    FuseNavigationService,
} from '../../../@fuse/components/navigation';
import { environment } from '../../../environments/environment';
import { EventType } from '../../shared/enums/event.type.enum';
import { Utils } from '../../shared/helpers/utils';
import { Permission, Role } from '../../shared/models/modelBanks';
import { ApiService } from '../api/api.service';
import { EventsService } from '../config/events.service';
import { AuthenticationService } from '@fuse/services/crypt-decrypt/authentication.service';


@Injectable()
export class AuthService {
    private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    user$: Observable<any> = this.userSubject.asObservable();

    private user: any; 
    setUser(user: any): void {
        this.user = user;
        this.userSubject.next(user);
    }

    getUser(): any {
        return this.user;
    }



    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService,
        private _nav: FuseNavigationService,
        private _eventsService: EventsService,
        private _router: Router,
        private authenticationService: AuthenticationService,
    ) {
        this.permissionListener();
        this.listenForEvents();
    }

    private _authenticated: boolean = false;
    private roles: Role[] = [];
    private $role: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>(
        this.roles
    );
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private activeMenus: FuseNavigationItem[] = [];
    private permissions: Permission[] = [];
    private $permissions: BehaviorSubject<Permission[]> = new BehaviorSubject<
        Permission[]
    >(this.permissions);
    private $activeMenus: BehaviorSubject<FuseNavigationItem[]> =
        new BehaviorSubject<FuseNavigationItem[]>(this.activeMenus);
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('ULINZI', token);
    }

    get accessToken(): string {
        return localStorage.getItem('ULINZI') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(payload: any): Observable<any> {
    
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.auth.forgotPassword, payload)
            .pipe(
                switchMap((response: any) => {
                    //Decrypting of the response                    
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    confirmOtp(payload: any): Observable<any> {

        return this._apiService
            .postWithHeadersStaticSecretKey(environment.url.auth.confirmOtp, payload, {
                withCredentials: true,
            })
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        //   const authenticatedUser: User = response;
                        // Store the access token in the local storage
                        // this.fetchAllUserRoles(authenticatedUser);
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /*post data with auth*/

    /**
     * Reset password
     *
     * @param payload
     * @param mode
     */
    resetPassword(payload: FormData): Observable<any> {
    
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.auth.resetPassword, payload)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        if (response['return_code'] === '00') {
                            // Set the authenticated flag to false
                            this._authenticated = false;
                        }
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        let headers = new HttpHeaders();
        headers = headers.set('uuid', 'f2403cbe8c42f880');
        return this._apiService
            .postWithHeadersStaticSecretKey(environment.url.auth.login, credentials, headers)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        if (response['return_code'] === '00') {
                            //   const authenticatedUser: User = response;
                            // Store the access token in the local storage
                            this.accessToken = response.data;
                            // TODO Get user details from backend on successful authentication
                            // localStorage.setItem('user_email', String(credentials.get('email')));
                            // Set the authenticated flag to true
                            this._authenticated = true;
                        }
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        // TODO: token renewal logic
        return of(null);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        let itemsToRemove = ['ULINZI', 'USERNAME', 'OTP', 'serviceCode'];
        itemsToRemove.forEach((item) => {
            localStorage.removeItem(item);
        });
        // Set the authenticated flag to false
        this._authenticated = false;
        // Return the observable
        return of(true);
    }

    listenForEvents(): void {
        this._eventsService.event$
            .pipe(
                // listen only for events that concern auth service
                takeWhile((event) =>
                    [EventType.LOGOUT].some(
                        (eventType) => eventType === event.type
                    )
                ),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((portalEvent) => {
                if (portalEvent.type === EventType.LOGOUT) {
                    // Remove the access token from the local storage
                    localStorage.clear();
                    // Set the authenticated flag to false
                    this._authenticated = false;
                    //Redirect User to Sign In Page
                    //  console.log('automaticaly logged out !');
                    this._router.navigate(['/sign-out']);
                }
            });
    }

    /**
     * Sign up
     *
     * @param payload
     */
    signUp(payload: FormData): Observable<any> {
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.signUp.submitService, payload)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        // let authenticated flag remain false
                        this._authenticated = false;
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }
    ChangeIBpassword(payload: FormData): Observable<any> {
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.ChangeIBpassword.submitService, payload)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        // let authenticated flag remain false
                        this._authenticated = false;
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }
    resetAccount(payload: FormData): Observable<any> {
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.resetAccount.submitService, payload)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        // let authenticated flag remain false
                        this._authenticated = false;
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }


    /**
     * Sign up
     *
     * @param payload
     */
    deactivate(payload: FormData): Observable<any> {
        return this._apiService
            .postWithStaticEncryptionKey(environment.url.deactivate.submitService, payload)
            .pipe(
                switchMap((response: any) => {
                    if (response) {
                        // let authenticated flag remain false
                        this._authenticated = false;
                    }
                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    updateRoleList(): void {
        const roleIds: number[] = this.roles.map((role) => role.id);
        // remove duplicates
        // const uniqueRoleIds = [...new Set(roleIds)];
        // this.roles = this.roles.filt++ er((role) => uniqueRoleIds.indexOf(role.roleId) > -1);

        Utils.removeDuplicates(this.roles, (obj1, obj2) => {
            return obj1.id === obj2.id;
        });

        this.$role.next(this.roles);
    }

    resolveMenus(menuPermissions: Permission[]): FuseNavigationItem[] {
        const menus = defaultNavigation;
        const activeMenus = menuPermissions.map((_menuPermission) => {
            return this.createNavigationItem(_menuPermission, menus);
        });
        //console.log('All menus >>>', activeMenus);
        // Update all fuse menu configs
        [
            'default',
            'futuristic',
            'modern',
            'compact',
            'enterprise',
            'modern',
            'classy',
        ].forEach((navigationLayout) => {
            this._nav.storeNavigation(navigationLayout, activeMenus);
        });
        const navigation = {
            compact: activeMenus,
            default: activeMenus,
            futuristic: activeMenus,
            horizontal: activeMenus,
        };
        this._eventsService.publish({
            type: EventType.UPDATE_MENUS,
            data: navigation,
        });
        return activeMenus;
    }

    createNavigationItem(
        permission: Permission,
        defaultMenus: FuseNavigationItem[]
    ): FuseNavigationItem {
        const hasChildren =
            permission.children && permission.children.length > 0;
        return {
            title: permission.title,
            icon: permission.icon,
            type: hasChildren ? 'collapsable' : 'basic',
            link: permission.link !== '0' ? permission.link : null,
            children: hasChildren
                ? permission.children.map((child) => {
                      return this.createNavigationItem(child, defaultMenus);
                  })
                : null,
        };
    }

    appendChildren(parentPermission, permissions): Permission {
        parentPermission._children = permissions.filter(
            (_childPermission) =>
                _childPermission.parentId === parentPermission.permissionId
        );
        parentPermission._children.forEach((_childPermission) => {
            this.appendChildren(_childPermission, permissions);
        });
        return parentPermission;
    }

    permissionListener(): void {
        this.$permissions.subscribe((permissions) => {
            this.resolveMenus(permissions);
        });
    }

    resolvePermissions(): void {
        //  console.log('Resolving permissions >>>', this.$permissions.getValue());
        this.getMenus().subscribe((r) => r);
        // console.log();
    }

    getMenus(): Observable<any> {
        

        return this._apiService
            .getWithParams(environment.url.menus.getMenus, {})
            .pipe(
                map((rawResponse) => {
                    const res = JSON.parse( this.authenticationService.decryptWithSessionKey(rawResponse['hashedBody']));   
                    //   console.log('Permissions get menus -> ', res);
                    if (Utils.responseIsSuccess(res)) {
                        this.$permissions.next(res.entity);
                        
                    } else {
                        //     console.error('Permission fetch failed ', res);
                    }
                })
            );
    }

    getChildPermissions(permissionName: string): Observable<any> {
        return this._apiService
            .getWithParams(environment.url.permissions.getChildrenPermissions, {
                name: permissionName,
            })
            .pipe(
                map((response) => {
                    if (Utils.responseIsSuccess(response)) {
                        const permissions = this.$permissions.getValue();
                        response.entity.forEach((_childPermission) => {
                            permissions.push(_childPermission);
                        });
                        // console.log('All permissions ', permissions);
                        this.$permissions.next(permissions);
                    }
                    return response;
                })
            );
    }

    refreshToken() {
        return this._apiService.post('/refreshToken', []).subscribe((rawResponse) => {
            const response = JSON.parse( this.authenticationService.decryptWithSessionKey(rawResponse['hashedBody']));   
            if (response['responseCode'] === '00') {
                console.log('token returned successfully ### ', response['message']);
                localStorage.setItem('ULINZI', response['message']);
            } else {
                //log out
                this.signOut();
            }
        });
    }
}
