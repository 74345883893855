import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import * as chroma from 'chroma-js';
import { fromEvent, Observable, Subscription } from 'rxjs';
import * as generateContrasts from '../@fuse/tailwind/utils/generate-contrasts';
import * as generatePalette from '../@fuse/tailwind/utils/generate-palette';
import { environment } from '../environments/environment';
import { AlertsService } from './core/config/alerts.service';
import { disableLogsProd } from './core/config/disableLogsProd.service';
import { Utils } from './shared/helpers/utils';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    offlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];
    configLoaded = false;
    envConfig: any;
    domain = '';
    splt: any;
    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private http: HttpClient,
        private rightClickDisable: disableLogsProd,
        private disableLogsProd: disableLogsProd,
        private _alertsService: AlertsService
    ) {}

    async ngOnInit(): Promise<void> {
        //disable logs in production environment
        this.disableLogsProd.disableConsoleInProduction();
        //check cookies
        this.disableLogsProd.cookieCheck();
        //check internet connection
        this.isUserOffline();
        //disable right click method
        this.rightClickDisable.disableRightClick();
        this.setTheme();
    }

    //check if offline
    isUserOffline() {
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.push(
            this.offlineEvent.subscribe((e) => {
                this._alertsService.toastNotification(
                    'Internet Disconnected',
                    'warning'
                );
                console.log('Offline>>>');
            })
        );
    }

    copySourceToDestination(source: any, destination: any): void {
        Object.keys(source).forEach((k) => {
            //  console.log('override >>>', k, typeof k);
            if (typeof source[k] === 'object' && source[k] !== null) {
                this.copySourceToDestination(source[k], destination[k]);
            } else {
                destination[k] = source[k];
            }
        });
    }

    setTheme(): void {
        Object.keys(environment.theme.brand).forEach((k) => {
            document.body.style.setProperty(
                `--fuse-${k.toLowerCase()}`,
                environment.theme.brand[k]
            );
            document.body.style.setProperty(
                `--fuse-${k.toLowerCase()}-rgb`,
                chroma.hex(environment.theme.brand[k]).rgb().join(',')
            );
            const palette = generatePalette(environment.theme.brand.primary);
            const paletteDark = generateContrasts(palette);
            document.body.style.setProperty(
                `--fuse-${k.toLowerCase()}`,
                environment.theme.brand[k]
            );
            document.body.style.setProperty(
                `--fuse-${k.toLowerCase()}-rgb`,
                chroma.hex(environment.theme.brand[k]).rgb().join(',')
            );
            // console.log('Dark palette', paletteDark);
            Object.keys(palette).forEach((paletteKey) => {
                document.body.style.setProperty(
                    `--fuse-${k}-${paletteKey}`,
                    palette[paletteKey]
                );
                document.body.style.setProperty(
                    `--fuse-${k}-${paletteKey}-rgb`,
                    chroma.hex(palette[paletteKey]).rgb().join(',')
                );
            });
            Object.keys(paletteDark).forEach((paletteKey) => {
                document.documentElement.style.setProperty(
                    `--fuse-on-${k}-${paletteKey}`,
                    paletteDark[paletteKey]
                );
                document.documentElement.style.setProperty(
                    `--fuse-on-${k}-${paletteKey}-rgb`,
                    chroma.hex(paletteDark[paletteKey]).rgb().join(',')
                );
            });
        });
        document.body.style.setProperty(
            `--c-login-bg`,
            environment.theme.login_primary_color ||
                environment.theme.brand.primary
        );
        document.body.style.setProperty(
            `--c-sidebar-primary-bg`,
            environment.theme.sidebar_primary_color ||
                environment.theme.brand.primary
        );
        document.body.style.setProperty(
            `--c-dashboard-header-bg`,
            environment.theme.dashboard_background_color ||
                environment.theme.brand.primary
        );
        document.body.style.setProperty(
            `--c-text-color-bg`,
            environment.theme.text_color
        );
    }
}
