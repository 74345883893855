import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { UserIdleService } from 'angular-user-idle';
import { InitialData } from 'app/app.types';
import { AuthService } from 'app/core/auth/auth.service';
import { EventsService } from 'app/core/config/events.service';
import { EventType } from 'app/shared/enums/event.type.enum';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    // load logo from json
    companyDetails = {
        logo: environment.companyLogo.logo,
        name: environment.companyMotto.title,
    };
    private isActive = true;

    styles = {
        sidebarAdditionalClasses: environment.theme.sidebar_additional_styling,
    };
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _eventService: EventsService,
        private userIdle: UserIdleService,
        private authService: AuthService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route mock-api
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.menuChangeListener();

        //Start watching for user inactivity.
        this.userIdle.startWatching();
        
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => console.log("count ###", count));
        
        // Subscribe to the onPing event
        this.userIdle.ping$.subscribe(() => {
         if (this.isActive) {
        // Call refresh token API and get the new token
        //  this.authService.refreshToken();
         }
        });

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
        this.isActive = false;
        // Perform logout logic here.
        this.authService.signOut();
        this._router.navigate(['/sign-in']);
        // Stop watching for user inactivity until the user logs in again.
        this.userIdle.stopWatching();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    menuChangeListener(): void {
        this._eventService.event$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                if (res.type === EventType.UPDATE_MENUS) {
                    this.data.navigation = res.data;
                }
            });
    }

}
