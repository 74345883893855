import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { MaterialComponentsModule } from './material-components.module';
import { ButtonComponent } from './components/button/button.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { DateComponent } from './components/date/date.component';
import { InputComponent } from './components/input/input.component';
import { FilterFormComponent } from './components/filter-form/filter-form.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {SelectComponent} from './components/select/select.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { TextSliderComponent } from './components/text-slider/text-slider.component';
import { CodeInputModule } from 'angular-code-input';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialComponentsModule,
        NgxDatatableModule,
        NgxUiLoaderModule,
        SweetAlert2Module,
        MatDialogModule,
        CodeInputModule.forRoot({
            codeLength: 4,
            isCharsCode: false
          }),
    ],
    declarations: [
        CustomTableComponent,
        ButtonComponent,
        CheckBoxComponent,
        DateComponent,
        InputComponent,
        FilterFormComponent,
        SelectComponent,
        GenericDialogComponent,
        RadioButtonComponent,
        TextSliderComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialComponentsModule,
        FilterFormComponent,
        SelectComponent,
        CustomTableComponent,
        NgxUiLoaderModule,
        SweetAlert2Module,
        GenericDialogComponent,
        TextSliderComponent
    ],
    providers: [
        {provide: MatDialogRef , useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ]
})
export class SharedModule
{
}
