import {
    Component,
    ViewChild,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { FieldConfig } from '../../models/modelBank';
import * as moment from 'moment';

@Component({
    selector: 'app-filter-form',
    templateUrl: './filter-form.component.html',
    styleUrls: ['./filter-form.component.scss'],
})
export class FilterFormComponent implements OnInit {
    @Input() formConfigs: FieldConfig[];
    /** trigger submit externally (eg. from a button outside the form) */
    @Input() set triggerSubmit(value: boolean) {
        this.onSubmit();
    }
    @Output() formResult = new EventEmitter<any>();
    @Output() inputChange = new EventEmitter<any>();
    myFormGroup: FormGroup;
    formTemplate: FieldConfig[];

    constructor() {}

    ngOnInit(): void {
        this.formTemplate = this.formConfigs;
        const group = {};
        this.formConfigs.forEach((input_template) => {
            if (input_template.type !== 'button') {
                group[input_template.name] = new FormControl(
                    '',
                    this.bindValidations(input_template.validations || [])
                );
            }
        });
        this.myFormGroup = new FormGroup(group);
    }

    onSubmit() {
        const formValue: Object = this.myFormGroup.value;
        console.log('Form Submitted:', formValue);
        Object.keys(formValue).forEach((k) => {
            if (formValue[k] instanceof moment) {
                console.log(
                    'Key ' + k + ' is instance of moment. Value >>',
                    formValue[k]
                );
                formValue[k] = formValue[k].format('YYYY-MM-DD');
                console.log('after transformation to string >>', formValue[k]);
            }
        });
        this.formResult.emit(formValue);
    }

    bindValidations(validations: any) {
        if (validations.length > 0) {
            const validList = [];
            validations.forEach((valid) => {
                validList.push(valid.validator);
            });
            return Validators.compose(validList);
        }
        return null;
    }

    onClearForm(value, formDirective: NgForm) {
        formDirective.resetForm();
        this.myFormGroup.reset();
    }

    /**
     * Attached to the input change events of child input fields. When a change happens in the child input fields,
     * they notify this form (parent form) which notifies the parent component.
     * All events from the different input fields pass through here, so in order to differentiate the initiator(owner)
     * of the event, one has to check the name in the event object
     *
     * @param event
     */
    onInputChange(event: any): void {
        this.inputChange.emit(event);
    }
}
