import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IBPortalEvent } from '../../shared/models/common.model';

/**
 * This service allows for project-wide inter-communication for components regardless of the
 * family tree. It uses event publication and subscription through observable to manage state
 */
@Injectable({
    providedIn: 'root',
})
export class EventsService {
    constructor() {}

    private _event: Subject<IBPortalEvent> = new Subject<IBPortalEvent>();
    eventStore: IBPortalEvent[] = [];

    get event$(): Observable<IBPortalEvent> {
        return this._event.asObservable();
    }

    publish(event: IBPortalEvent): void {
        this._event.next(event);
        this.eventStore.push(event);
    }
}
