// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    secretKey: 'U1AwdGNhU0hNMFZ6RTFuQQ==',
    production: false,

    url: {
        // base url
        base: 'https://localhost:9094/ibanking/api',
        auth: {
            login: '/verifymsisdn',
            forgotPassword: '/forgotpassword',
            confirmOtp: '/validateotp',
            resetPassword: '/changepassword',
            deactivate: '/deactivate',
                    },

        //error
        error: {
            error: '/error-500/',
        },

        // Permission
        permissions: {
            create: '/permission/create',
            update: '/permission/update',
            findById: '/permission/findById',
            userFindByStatus: '/permission/userFindByStatus',
            getMenus: '/getMenus/',
            getChildrenPermissions: '/getChildrenPermissions/',
        },

        //sidebar menus
        menus: {
            getMenus: '/getMenus/',
            postFeedback:'/postFeedback',
            personalDetails:'/getDetailedMemberInfo',
            referFriend:'/submitFormData'
        },

        notifications:{
            getMessages:'/getMessages',
            getFlyers:'/getFlyers',
            getCustomFlyers:'/getCustomFlyers'
        },

        // dashboard statistics
        api: {
            customertransactions: '/customertransactions',
            readServices: '/getDashboardServices/',
        },
        dashboardServices: {
            readServices: '/getDashboardServices/',
            subMenuService: '/getChildServices/',
            validateService: '/validateParams/',
            updateFields: '/updateServiceConfig/',
            submitService: '/submitFormData/',
            submitServiceOtp: 'submitFormData',
        },

        //service enquiries
        enquiries: {
            read: '/getEnquiryServices/',
            savingsBalance: '/submitFormData/',
        },
        loans: {
            read: '/getLoanServices/',
            savingsBalance: '/submitFormData/',
        },

        //get services
        allServices: {
            read: '/getserviceconfig/',
        },

        //change password
        ChangeIBpassword: {
            readServices: '/fetchSecurityQuestions',
               submitService: '/ChangeIBpassword',
             },
             
        // manual Loans
        manualLoans: {
            manualLoanTypes: '/manualLoanTypes/',
            getModesOfDisbursement: '/getModesOfDisbursement/',
            apply: '/submitManualLoan/',
            getsecurityTypes : '/getSecurityTypes/',
            createFile: '/createFile/',
            generateOtp: '/generateManualLoansOtp/',
            verifyOtp: '/validateManualLoansOtp',
            fetchDocByLoanId: '/fetchByLoanId/',
            submitManualLoan: '/submitManualLoan/',
            validateGurantor: '/validateGurantor/',
            addGuarantor: '/addGuarantor/',
            listGuarantor: '/listGuarantors',
            fetchLoanStatus: '/fetchLoanStatus',
            guarantorloans: '/guarantor_loans',
            listLoans: '/listCustomerManualLoans',
            fetchLoanDetails: '/fetchLoanDetails',
            myGuaranteedLoans: '/myGuaranteedLoans',
            approveOrReject: '/approveOrReject',
            getUserSignature: '/getUserSignature',
            getLoanPurpose: '/getLoanPurpose/',
            getRepaymentMethods: '/getRepaymentMethods/',
            applyManualLoan: '/applyManualLoan/',
            replaceGuarantor: '/updateGuarantor/'

        },

        //loan applications
        loansApplication: {
            create: '/validateParams/',
            findById: '/enquiries/read/',
        },

        //transactions
        transactions: {
            read: '/gettransactionsPagination/',
        },

        //statements
        statements: {
            fetchAllAccounts: '/fetchMemberAccounts/',
            validateService: '/validateParams/',
            submitService: '/submitFormData/',
        },

        //calculator
        calculator: {
            readServices: '/getMenus/',
            loanProducts: '/loanProducts/',
            submitService: '/loanCalculator/',
            referFriend:'/referFriend/',
        },

        //receipts
        receipts: {
            readServices: '/getMenus/',
            validateService: '/validateParams/',
            submitService: '/submitFormData/',
        },

        //terms and conditions agreements
        agreements: {
            readServices: '/getTermsAndConditions',
        },

        //sign up
        signUp: {
            readServices: '/fetchSecurityQuestions',
            submitService: '/submitRegistrationData',
        },
         //resetAccount
         resetAccount: {
            readServices: '/fetchSecurityQuestions',
            submitService: '/ResetInternetBankingAccount',
        },


        //Deactivate
        deactivate: {
            getQuestions: '/fetchUserSecurityQuestions',
            submitService: '/deactivateAccount',
        },


        //fetch devices and user profile
        myDevices: {
            getDevices: '/getDevices',
            removeDevice: '/removeDevices',
            getAccountDetails: '/getUserProfile',
            updateProfilePicture: '/updateUserPicture',
            getProfilePicture: '/getUserPicture',
            updateUserSignature: '/updateUserSignature',
            fetchUserSignature:"/fetchUserSignature"
        },

        //fetch member accounts
        memberAccounts: {
            getMemberAccounts: '/fetchMemberAccounts'
        },

         //fetch member details
         memberDetails: {
            getMemberDetails: '/memberDetails'
        },
        //fetch service  charge
        serviceCharge: {
        getServiceCharge: '/getServiceCharge'
        },
        //fetch configurations
        configurations: {
            fetchConfigurations: '/getIBConfigurations'
        },
    },
    captchaKey: {
        captcha: '6LdaztYfAAAAANcetku_eS8w3VQzKR7va_nsFDMc',
    },
    saccoId: {
        cliendId: 1,
    },
    companyMotto: {
        title: 'TANGAZOLETU INTERNET BANKING',
        motto: 'Your concern is our agenda',
        mission:"Tangazoletu Mission",
        slogan:"If you think it, It's do-able",
        values: "value 1, value 2, value 3"
    },
    otpMasking: {
        masked: true,
    },
    showCaptcha: {
        show: true,
    },
    companyLogo: {
        logo: 'spotcash.png',
    },
    dashboard:{
        alternate_dash:1
    },
    signIn: {
        sign_in_flyer:1
    },
    view_company_title: 1,
    constants: {
        emailValidationPattern:
            "^(?=.{1,256})(?=.{1,64}@.{1,255}$)(?=\S)(?:(?!@)[\w&'*+._%-]+(?:(?:(?<=\.)\w{2,63})|(?<!\.)\w{0,62}\w(?<!\.)))?)*@[A-Za-z0-9-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$",
        beNameValidationPattern: '[a-zA-Z0-9].*[s.]*$',
        phoneValidationPattern: '^[0-9]{10,12}$',
    },
    theme: {
        /** If true, this is the theme that will be placed. Otherwise, it will be ignored */
        override: true,
        brand: { primary: '#DO312D', accent: '#990F02', warn: '#610C04' },
        login_primary_color: '#343d52',
        sidebar_primary_color: '#343d52',
        dashboard_background_color: '#343d52',
        sidebar_additional_styling: 'dark',
        text_color: '#ffffff',
        header_text_color: '#ffffff',
        auth_color:'#488A3A',
    },

    sessionTimeout: {
        idle: 600,
        timeout: 10,
        ping: 120,
    },
    ollin_sign_color: 1,
    shelloyees_dash_color: 1,
    view_company_values: 1,
    loanCalculator:{
        deposit:1,
        startDate:1
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
