<div class="w-full" >
    <mat-form-field class="demo-full-width w-full margin-top" [formGroup]="group">
        <input matInput [matDatepicker]="picker" [formControlName]="field.name" [placeholder]="field.label"
               [min]="field.dateConfigs?.minDate ? field.dateConfigs.minDate : defaultMinDate "
               [max]="field.dateConfigs?.maxDate ? field.dateConfigs.maxDate : defaultMaxDate" (change)="onChange($event)"
               [disabled]="field.disabled">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [startView]="field.dateConfigs?.startView ? field.dateConfigs.startView : 'year'" #picker></mat-datepicker>
        <mat-hint></mat-hint>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
      </mat-form-field>
</div>

