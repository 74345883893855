import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from 'app/shared/models/modelBank';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  @Output() onClear = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.onClear.emit("CLEAR");
  }

}
